@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

footer {
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 75px;
  bottom: 0;
  left: 0;
  padding: 20px 0 16px;
  padding-left: 58px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 40px;
  z-index: 1;

  .footer-container {
    max-width: 1564px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 20px;
  }
  .footer-row {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
  }
  a, a:visited {
    text-decoration: none;
    color: #38434C;
  }
  .footer-text {
    margin-left: 20px;
    h3 {
      color: #38434C;
      text-align: center;
      font-family: $baseFont;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      color: #6A7885;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $baseFont;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 4px;
    }
  }
}
@import "./src/utils/fonts/roboto";
@import "./src/utils/variables/input";
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.simple-input-container {
  .react-select__control {
    font-size: 16px;
    font-weight: 400;
    font-family: $baseFont;
    background-color: $defaultBackgroundColor;
    border-radius: 4px;
    border: .5px solid $borderColor;
    min-height: 28px;
    max-height: 28px;
    margin-left: 5px;
    margin-right: 5px;
    height: 28px;
    max-width: 345px;

    &:focus {
      border: .5px solid $brandForegroundColor;
    }
  }

  .react-select__single-value {
    margin: 0;
    color: $blackBaseColor;
  }

  .react-select__value-container {
    height: 28px;
  }

  .react-select__input-container {
    height: 28px;
    padding: 0;
    margin: 0;

    .react-select__input {
      height: 28px;
    }
  }

  .react-select__indicators {
    height: 28px;
    width: 20px;
  }

  .react-select__dropdown-indicator {
    padding: 0;
  }

  .react-select__control--is-focused {
    border: .5px solid $brandForegroundColor;
    box-shadow: none;

    .react-select__indicator {
      color: $blackBaseColor !important;
    }

    &:hover {
      border-color: $brandForegroundColor;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    color: $blackBaseColor;
    border-radius: 4px;
    font-family: $baseFont;
    text-transform: none;
    z-index: 2;
  }

  .react-select__option--is-focused {
    background-color: #f5f5f5 !important;
    color: $blackBaseColor;
  }

  .react-select__option--is-disabled {
    color: $defaultForegroundDisabledColor;
  }

  .react-select__option--is-selected {
    background-color: $defaultForegroundDisabledColor !important;
    color: $blackBaseColor;
  }

  .react-select__placeholder {
    font-size: 16px;
    margin: 0;
    color: $defaultForegroundDisabledColor;
  }

  .select-is-not-valid {
    .react-select__control {
      border: 2px solid $redBaseColor;
    }
  }
}

@import "./src/utils/variables/base";

.horizontal-input {
  .radios {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-top: 10px;
  }
}

.input {
  .radio-container {
    display: flex;
    align-items: center;
    margin-right: 25px;
    &:last-child {
      margin-right: 0;
    }

    input[type="radio"] {
      border: 1px solid #9ca5ad;
      float: left;
      height: 20px;
      margin: 0 10px 0 0;
      width: 20px;
      cursor: pointer;
    }

    span {
      color: $blackBaseColor;
    }
  }
}

//Styles for the radio - only input, without label
.only-input-container .horizontal-input {
  padding: 0;

  .radios {
    margin-top: 0;
    padding: 0;
    display: block;
    .radio-container {
      margin-bottom: 10px;
    }
  }

  .input {
    display: flex;
  }

  label {
    display: none;
  }
}

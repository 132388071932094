@import "./src/utils/variables/base";

@media (max-width: 1360px) {
  .outgoing-employees-widget {
    padding: 20px 0 10px 40px;
    .outgoing-employees-absence-text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1361px) {
  .outgoing-employees-widget {
    padding: 20px 0 10px 60px;
    .outgoing-employees-absence-text {
      font-size: 18px;
    }
  }
}

.pb-20 {
  padding-bottom: 20px!important;
}

.outgoing-full-height {
  height: 397px!important;
  padding-bottom: 20px!important;
}
.outgoing-employees-widget-main {
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  border-radius: 12px;
  margin-top: 25px;
  padding: 12px;
  -webkit-backdrop-filter: blur(17.1695px);
  backdrop-filter: blur(17.1695px);
  background: linear-gradient(100.6deg,#fff .61%,#eff5ff 54.71%,#f4f6fa);
  .outgoing-employees-widget {
    margin-right: -12px;
    backdrop-filter: blur(17.1695px);
    .outgoing-employees-block-title {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #152029;
      margin-bottom: 16px;
    }

    .outgoing-employees-block-description {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #003563;
    }

    .outgoing-employees-body {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .outgoing-employees-body::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 82px;
      background: linear-gradient(90deg, rgba(241, 245, 252, 0.00) 2.7%, rgba(242, 245, 251, 0.91) 100%);
      pointer-events: none;
      z-index: 2;
    }

    .outgoing-employees-absence-text {
      display: flex;
      flex-direction: column;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      align-items: center;
      text-align: center;
      color: #003563;
    }

    .outgoing-employees-block {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      overflow: auto;
      .outgoing-employee-card {
        margin: 0 0 10px 0;
      }
    }

    .outgoing-employees-block::-webkit-scrollbar {
      height: 8px;
    }

    .employee-card-link {
      text-decoration: none;
      color: inherit;
    }

    .employee-card {
      width: 222px;
      height: 290px;
      border-radius: 10px;
      margin-right: 12px;
      border: 2px solid var(--blue-lighter-2, #F6F8FD);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .employee-card__avatar, .employee-card__no-avatar {
        width: 218px;
        height: 164px;
        border-radius: 10px 10px 0 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
      }
      .employee-card__no-avatar {
        background-color: #D98304;
      }
      .employee-card__avatar .employee-card__avatar__company,
      .employee-card__no-avatar .employee-card__avatar__company {
        position: absolute;
        bottom: 8px;
        left: 12px;
        color: var(--white, #FFF);
        text-align: center;
        font-family: $baseFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: inline-block;
        padding: 0 4px;
        align-items: center;
        gap: 10px;
        border-radius: 2px;
        border: 1px solid #434343;
        background: rgba(21, 32, 41, 0.50);
        backdrop-filter: blur(9px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 195px;
      }

      .employee-card__details {
        font-family: $baseFont;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: 100px;
        padding: 0 12px;

        .employee-card__nickname {
          color: var(--black-black-dark, #152029);
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 6px;
          margin-top: 8px;
        }

        .employee-card__jobtitle {
          color: $blackBaseColor;
          font-size: 14px;
          line-height: 140%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .employee-card__termination-date {
          color: $blackBaseColor;
          font-size: 14px;
          position: absolute;
          bottom: 2px;

          display: flex;
          align-items: center;
          margin-left: -2px;

          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
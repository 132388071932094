@font-face {
  font-display: block;
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: block;
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@import "./src/utils/variables/base";

.drag-and-drop {
  label {
  width: 345px;
  height: 120px;
  background: #FCFDFE;
  border: 0.5px solid #C2CAD1;
  border-radius: 4px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
    .material-icons {
      font-size: 34px;
      width: 36px;
    }
  }
  .fGupPU {
    border: 2px dashed #0052CC;
    border-radius: 4px;
    background: none;
    -webkit-transition: width 0.25s ease, margin 0.25s ease;
    transition: width 0.25s ease, margin 0.25s ease;
  }
  .preview {
    margin-top: 15px;
    margin-left: 15px;
    max-width: 345px;
    img {
      width: 100%;
    }
  }
  .delete-img {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 15px;
    background: #ff4d4f;
    font-size: 18px;
    padding: 3px;
  }
  .delete-img:hover {
    background: #ff7a7b;
    transition: 1s;
  }
}
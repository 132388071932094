.visibility {
  $height: 19px;

  position: absolute;
  margin-top: calc(#{$height} / -2);
  top: 50%;
  display: flex;
  align-items: center;
  height: $height;
  cursor: pointer;
}

.visibility-visible {
  .eye-opened {
    display: block;
  }
  .eye-closed {
    display: none;
  }
}

.visibility-invisible {
  .eye-opened {
    display: none;
  }
  .eye-closed {
    display: block;
  }
}
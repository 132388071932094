@import "./src/utils/variables/base";

.react-datepicker {
  border: none!important;
  border-radius: 12px!important;
  color: #152029!important;
  display: inline-block;
  font-family: $baseFont!important;
  position: relative;
  width: 300px!important;
  height: auto!important;
  padding-bottom: 10px!important;
  font-size: 18px!important;
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #F7FAFF 54.71%, #F4F6FA 100%)!important;
  box-shadow: 0 76.2792px 116.538px rgb(0 39 140 / 13%);
  .react-datepicker__day-name {
    color: #38434c;
    display: inline-block;
    line-height: 33px!important;
    margin: 0.166rem!important;
    text-align: center!important;
    width: 33px!important;
  }
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  color: #757575!important;
  cursor: default;
}
.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  background-color: #fff0;
  border-radius: 0.3rem;
  color: #0051ca!important;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #fff0;
}

.react-datepicker__navigation-icon {
  font-size: 20px!important;
  position: relative!important;
  top: -9px!important;
  width: 0!important;
}

.react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name {
  color: #152029;
  display: inline-block;
  margin: 0!important;
  text-align: center;
  width: 38px!important;
  padding: 0!important;
  line-height: 38px!important;
  font-weight: 400!important;
  font-size: 14px!important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #152029;
  background-color: transparent!important;
}
.react-datepicker__month-container {
  float: none!important;
}
.react-datepicker__navigation--previous, .react-datepicker__navigation--next {
  top: 9px!important;
}
.react-datepicker__input-container {
  input {
    background-color: #fcfdfe;
    border: 0.5px solid #c2cad1;
    border-radius: 4px!important;
    color: #38434c;
    font-family: Roboto, sans-serif!important;
    font-size: 16px;
    font-weight: 400;
    height: 44px!important;
    padding: 8px 12px!important;
    transition: border .1s ease-in-out, box-shadow .1s ease-in-out;
    width: 100% !important;
  }
  input:disabled {
    background-color: #ccc;
  }
}

.react-datepicker__day--selected:hover, .react-datepicker__day--selected {
  background-color: #E6EEFA!important;
  color: #0044AA!important;
  border-radius: 25px!important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 25px!important;
}
.react-datepicker__header {
  padding: 15px 0 0!important;
  position: relative;
  text-align: center;
  border-radius: 0;
  border-bottom: 0!important;
  background: none!important;
  border-bottom: 0;
  box-shadow: none;
  .react-datepicker__day-name {
    color: #6A7885!important;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
  }
  .react-datepicker__current-month {
    color: #003563!important;
    margin-top: 0!important;
    font-family: $baseFont!important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px!important;
    line-height: 23px!important;
    margin-left: -5px!important;
    padding-bottom: 12px!important;
    padding-top: 3px!important;
  }
}

//Styles for the datepicker - only input, without label
.only-input-container .horizontal-input {
  padding: 0;

  .react-datepicker__input-container input {
    height: 28px !important;
    width: 110px !important;
  }

  .input {
    display: flex;
  }

  label {
    display: none;
  }
}
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

html {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  background-color: $backgroundColor;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  overflow: auto;

  //CKEditor config variables
  --ck-border-radius: 4px;
  --ck-spacing-standard: 30px;
  .ck-content {
    font-family: $baseFont !important;
    color: $blackBaseColor !important;
  }
}

.text-center {
  text-align: center;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #E6EEFA;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: transparent;
}

select {
  height: 32px;
  padding: 0 30px 0 8px;
  background: #F2F2F2;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(105% - 20px) calc(1em + 2px), calc(105% - 15px) calc(1em + 2px), -1.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

table, .table {
  display: table;
  position:relative;
}
td, .td {
  display:table-cell;
  span{
    display: block;
    padding:15px;
    height: 50px;
    line-height: 20px;
  }

}
tr, .tr {
  display:table-row;
}

table tr:hover td {
  background-color: #fbfbfb;
}

.material-icons {
  color: #6A7885;
  vertical-align: text-bottom;
  margin-bottom: -3px;
  height: 24px;
}

select {
  cursor: pointer;
}
.material-icons-arrow-right {
  transform: rotate(-90deg);
}

.main-content {
  -webkit-transition: width 0.25s ease, margin 0.25s ease;
  transition: width 0.25s ease, margin 0.25s ease;
  margin-top: 56px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100vh - 56px);
}

.main-left-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 12px 12px 0;
  width: 100%;
  min-width: 832px;
}

.main-right-block {
  padding: 24px 0 12px 12px;
}

.main-flex {
  display: flex;
  width: 100%;
}
.sub-content-block {
  flex-direction: column;
  width: 100%;
  margin-left: 300px;
  margin-top: 75px;
    .filter {
      .rmsc {
        --rmsc-main: #f2f2f2;
        --rmsc-hover: #f1f3f5;
        --rmsc-selected: #fff;
        --rmsc-border: #ccc;
        --rmsc-gray: #aaa;
        --rmsc-bg: #fff;
        --rmsc-p: 10px;
        --rmsc-radius: 4px;
        --rmsc-h: 38px;
      }
      .multi-select-with-checkboxes {
        width: 230px;
        font-size: 14px;
        height: 28px;
        .dropdown-container {
          height: 28px;
          border: none;
        }
        .dropdown-heading {
          height: 28px;
          padding: 0 5px 0 10px;
          font-family: $baseFont;
          font-weight: 400;
          background: #F2F2F2;
          border-radius: 4px;
        }
        .dropdown-container {
          height: 28px;
          border: none;
          background: #F2F2F2;
          color: #38434c;
          border-radius: 4px;
        }
        .dropdown-container {
          height: 28px;
          border: none;
          background: #FFFFFF;
          font-size: 14px;
          font-weight: 400;
          font-family: $baseFont;
          color: #38434c;
        }
      }
    }
  .sub-content-header {
    flex-direction: row;
    height: 90px;
    padding-right: 40px;
    padding-top: 10px;
    margin-top: -11px;
    width: calc(100% - 367px);
    position: fixed;
    z-index: 2;
    background: $backgroundColor;

    .sub-title-block {
      width: fit-content;
      align-items: center;
      h3 {
        font-family: $baseFont;
        font-weight: 400;
        font-size: 18px;
        white-space: nowrap;
      }
    }

    .sub-header-filter-block {
      flex-direction: row;
      margin-left: 24px;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: 400;
        font-family: $baseFont;
      }
      .filter {
        margin-left: 12px;
      }
    }
    .add-button-block {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      align-items: center;
      button {
        font-size: 14px;
        font-weight: 500;
        font-family: $baseFont;
        padding: 5px 15px 5px 15px;
        line-height: 24px;
        margin-top: -5px;
        border-radius: 4px;
        cursor: pointer;
        height: 32px;
        box-shadow: $shadow2;
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
    }
  }
  .sub-content-main {
    padding-right: 40px;
    width: 100%;
    padding-top: 80px;
  }
}

.main-title-block {
  width: 100%;
  height: 64px;
  background: #FFF;
  box-shadow: $shadow2;
  padding: 16px 24px;
  position: fixed;
  z-index: 3;
  div {
    width: 32px;
    height: 32px;
    background-color: $brandForegroundColor;
    border-radius: 5px;
    float: left;
    justify-content: center;
    margin-right: 12px;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    font-family: $baseFont;
    line-height: 32px;
  }
  .material-icons {
    color: #FFF;
    line-height: 20px;
  }
}

.react-tooltip {
  background: #fff!important;
  box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
  color: #38434c!important;
  font-family: Roboto,sans-serif;
  margin-left: 145px;
  margin-top: -5px;
  max-width: 300px;
  opacity: 1!important;
  padding: 8px 12px!important;
  z-index: 5;
}
.flex-block-main {
  display: flex;
  flex: 1 0 auto;
}

.block-max-size {
  max-width: 1564px;
  margin: 56px auto 0;
  padding-left: 24px;
  padding-right: 20px;
}

.main-page {
  .main-content {
    width: 95% !important;
    display: block !important;
    .request-modal {
      .types-link {
        margin: 10px 0;
        a {
          color: #0052cc;
        }
      }
      .balance-field {
        .css-b62m3t-container {
          width: 100%!important;
        }
      }
      .react-select__control {
        max-width: 100%!important;
      }
      .icon-end {
        width: 24px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        margin-top: 18px;
        pointer-events: none;
        font-size: 21px;
        color: #AFB7BE;
        right: 9%;
      }
      .icon-start {
        width: 24px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        margin-top: 17px;
        pointer-events: none;
        font-size: 21px;
        color: #AFB7BE;
        left: 43%;
      }
      .modal-header .material-icons {
        color: #6A7885;
        cursor: pointer;
      }
      .modal-footer {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        padding: 12px 24px;
        background: #FCFDFE;
        box-shadow: inset 0 0.5px 0 0 #C2CAD1;

      }
      .modal-header {
        background-color: #F9FAFD;
        color: #152029;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 15px 35px;
        box-shadow: inset 0px -0.5px 0px #C2CAD1;
      }
      .modal-content {
        min-width: 497px!important;
        max-width: min-content!important;
        width: 497px!important;
      }
      .modal-body {
        padding: 0;
        .available-block {
          display: flex;
          justify-content: space-between;
          span:last-child {
            color: #6A7885;
          }
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #38434C;
          font-family: $baseFont;
          padding-top: 10px;
          span {
            font-weight: 400;
          }
          &:first-child {
            padding-top: 0;
          }
        }
        .date-block {
          display: flex;
          flex-direction: row;
          .react-datepicker__navigation {
            top: 20px !important;
          }
          .react-datepicker__day--disabled {
            color: #ccc !important;
          }
        }
        .horizontal-input label {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          min-width: auto;
          width: max-content;
          margin-right: 8px;
        }
        .css-b62m3t-container, .css-b62m3t-container {
          position: relative;
          box-sizing: border-box;
          min-width: 204px;
          width: max-content;
          margin-bottom: 10px;
        }
        .horizontal-input .input-container, .horizontal-input label {
          padding: 0;
        }
      }
    }
  }

  .nav-hidden {
    max-width: 58px !important;
    width: 5% !important;
    flex: none !important;
  }
}

.block-mid-max-size {
  max-width: 1564px;
  margin: 56px auto 0;
  min-height: calc(100vh - 170px);
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.sub-content-block {
  .generate-email-enabled {
    color: #0052CC;
    font-family: $baseFont;
    font-weight: 500;
    font-size: 14px;
    margin-left: 173px;
    cursor: pointer;
  }
  .generate-email-disabled {
    color: gray;
    font-family: $baseFont;
    font-weight: 500;
    font-size: 14px;
    margin-left: 173px;
    cursor: default;
  }
  .deleted-employees-table {
    width: 100%;
    border-collapse: collapse;
    a, a:visited {
      color: #152029;
      text-decoration: none;
    }
    .without-avatar {
      background-color: #9aa4be;
      border: 1px solid #e6ecf1;
      border-radius: 25px;
      display: inline-grid;
      margin-right: 8px;
      p {
        color: #fff;
        font-family: Roboto,sans-serif;
        line-height: 31px;
        text-align: center;
        width: 30px;
        height: 30px;
      }
    }
    .with-avatar {
      margin-right: 8px;
      display: inline-grid;
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      text-align: left;
      height: 56px;
      color: #6A7885;
      padding: 16px 24px;
      background: #F6F8FD;
      white-space: nowrap;
      &:last-child {
        width: 155px;
      }
      a {
        padding: 0 10px;
      }
    }
    td {
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
      border-radius: 2px;
      background: #FFF;
      padding: 16px 24px;
      border: none;
      color: $blackBaseColor;
      min-width: 155px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 780px;
      .material-icons {
        padding: 0 5px;
        font-size: 20px;
        cursor: pointer;
      }
      &:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
    }
    tr {
      border-bottom: 3px solid #f1f1f1;
      border-top: 3px solid #f9fafd;
    }
  }
}
@import "../../../../../utils/variables/base";
@import "../../../../../utils/variables/input";

.parent-modal-closed {
  display: none;
}

.parent-modal-opened {
  display: flex;
}

.parent-modal {
  width: 888px;
  height: 100%;
  flex-direction: column;
  font-family: $baseFont;
  font-style: normal;
  color: $blackBaseColor;
  align-items: center;
  .parent-modal-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 28px;
    gap: 12px;
    width: 796px;
    height: 24px;
    flex: none;
    flex-grow: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    .divider {
      display: block;
      width: 32px;
      height: 1px;
      background-color: $greyBorder;
    }
    .number {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #fff;
      color: $greyBorder;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 24px;
      margin-right: 5px;
    }
    .clickable {
      cursor: pointer;
      .number {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $brandForegroundColor;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 24px;
        margin-right: 5px;
        .material-icons {
          color: #fff;
        }
      }
    }
    .active-step{
      color: $brandForegroundColor;
    }
    .active-step-number {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 24px;
      margin-right: 5px;
      color: $brandForegroundColor;
      background-color: #E6EEFA;
    }
  }
}

.parent-modal-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 888px;
  height: 56px;
  background: #E6EEFA;
  border-radius: 6px;
  flex: none;
  flex-grow: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .material-icons {
    font-size: 20px;
    vertical-align: middle;
    margin-top: -20px;
  }
}

.approval-employee-select {
  min-width: 230px;
}
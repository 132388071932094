@import "../../../../../utils/variables/base";
@import "../../../../../utils/variables/input";
@import "../../../../../utils/variables/shadow";

.wide-page-modal-closed {
  display: none;
}

.wide-page-modal-opened {
  display: flex;
}

.wide-page-modal {
  width: 888px;
  height: 100%;
  flex-direction: column;
  font-family: $baseFont;
  font-style: normal;
  color: $blackBaseColor;
  align-items: center;
}

.wide-page-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 28px;
  width: 888px;
  background: #FFFFFF;
  box-shadow: 0 0.15px 0.45px rgba(0, 0, 0, 0.11), 0 0.8px 1.8px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
  .wide-page-modal-title {
    font-size: 18px;
    line-height: 150%;
    font-weight: 400;
    width: 760px;
    height: 35px;
    left: 64px;
    top: 16px;
    padding-left: 60px;
    color: $blackDark;
  }
  .wide-page-modal-line {
    content: '';
    width: 760px;
    height: 0.5px;
    background: #DFE1E6;
  }
  .wide-page-modal-body {
    width: 760px;
    .input .input-container .react-select__control {
      max-width: 387px;
    }
    .horizontal-input label {
      justify-content: start;
    }
  }
  .wide-page-modal-footer {
    width: 760px;
    text-align: right;
    margin-top: 15px;
  }
  .wide-page-modal-footer > * + * {
    margin-left: 10px;
  }
  .button {
    display: inline-block;
  }
  .wide-page-modal-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    width: 696px;
    height: 36px;
    background: #E6EEFA;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  .wide-page-input-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 28px;
    gap: 10px;
    width: 760px;
    background: #F9FAFD;
    border: 0.5px solid #C2CAD1;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 20px;
    .error {
      position: absolute;
      display: flex;
      padding: 6px 12px;
      left: 6px;
      top: 99%;
      background: $red;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      box-shadow: $shadow2;
      z-index: 1;
      min-width: max-content;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 16px;
        border: 7px solid transparent;
        border-top: none;
        border-bottom-color: $red;
      }

      svg {
        margin-right: 12px;
      }

      span {
        font-family: $baseFont;
        font-weight: 400;
        font-size: 14px;
        color: $redBaseColor;
      }
    }
    .wide-page-input-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .new-line {
        width: 100%;
        height: 16px;
      }
      .input-container {
        margin-top: -2px;
        margin-bottom: 10px;
      }
      .simple-input-container {
        margin-top: -3px;
      }
      .input, .input-container {
        position: relative;
        width: auto;
      }
    }
    .user-container {
      display: inline-block;
      align-items: center;
      padding: 4px 8px 4px 12px;
      border: 1px solid $greyBorder;
      border-radius: 33px;
      background-color: #fff;
      margin-right: 10px;
      color: $blackBaseColor;
      cursor: pointer;
    }
  }
}

.subtitle-black-dark {
  color: $blackDark;
}

.manually-adjusted-element {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  top: -68px;
  left: 28px;
}

.height-limit {
  max-height: 250px;
}

.wide-page-modal-line-short {
  content: '';
  width: 640px;
  height: 0.5px;
  background: #C2CAD1;
}
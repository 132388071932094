@import "./src/utils/variables/base";

@media (max-width: 1360px) {
  .greetings-widget {
    padding: 0 40px;
    .text {
      max-width: 605px;
      width: 605px;
    }
    p {
      font-size: 24px;
    }
  }
}

@media (min-width: 1361px) {
  .greetings-widget {
    padding: 0 60px;
    .text {
      width: 800px;
      max-width: 800px;
    }
    p {
      font-size: 28px;
    }
  }
}

.greetings-widget {
  height: 156px;
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  &:first-child {
    margin-top: 0;
  }
  .smiles {
    width: 180px;
    padding-left: 85px;
  }
  p {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    color: #152029;
    word-wrap: break-word;
    width: 100%;
  }
  .first-smile {
    background: linear-gradient(146.78deg, rgba(255, 255, 255, 0.1) 15.38%, rgba(255, 255, 255, 0.4) 92.12%);
    box-shadow: 0 76.2792px 116.538px rgb(0 39 140 / 13%);
    -webkit-backdrop-filter: blur(17.1695px);
    backdrop-filter: blur(17.1695px);
    border-radius: 20px;
    transform: rotate(-6.45deg);
    width: 81px;
    height: 81px;
    margin-top: -10px;
    position: absolute;
    margin-left: -70px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 39px;
      height: 39px;
    }
  }
  .second-smile {
    background: linear-gradient(146.78deg, rgba(255, 255, 255, 0.1) 15.38%, rgba(255, 255, 255, 0.4) 92.12%);
    box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
    backdrop-filter: blur(17.1695px);
    border: 2.119px solid rgba(255, 255, 255, 0.12);
    border-radius: 20px;
    transform: rotate(14.933deg);
    width: 81px;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 39px;
      height: 39px;
    }
  }
  .not-pointer {
    cursor: default;
  }
}
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.sub-content-block {
  .corporate-events {
    margin-bottom: 80px;
    .react-datepicker__input-container {
      width: 184px;
    }
    .tooltip-help {
      position: absolute;
      margin: -43px 0px 0px 365px;
      cursor: pointer;
    }
    .add-button-block {
      position: absolute;
      right: 50px;
      margin-top: -90px;
      z-index: 2;
    }
    .corporate-events-table {
      width: 100%;
      border-collapse: collapse;

      th {
        font-size: 14px;
        font-weight: 500;
        font-family: $baseFont;
        text-align: left;
        height: 56px;
        color: #6A7885;
        padding: 16px 24px;
        background: #F6F8FD;
        white-space: nowrap;

        &:last-child {
          width: 175px;
        }

        a {
          padding: 0 10px;
        }
      }

      td {
        font-size: 14px;
        font-weight: 400;
        font-family: $baseFont;
        border-radius: 2px;
        background: #FFF;
        padding: 16px 24px;
        border: none;
        color: $blackBaseColor;

        .modal-content {
          .input-container {
            text-align: left;
          }
          span {
            display: inline-flex;
            height: 0;
            line-height: 0;
            padding: 0;
          }
          .modal-body {
            p {
              span {
                height: auto;
                line-height: inherit;
              }
            }
          }
          .error span {
            line-height: initial;
            height: auto;
          }
        }

        .material-icons {
          padding: 0;
          cursor: default;
          font-size: 24px;
        }

        &:first-child {
          word-wrap: break-word;
          max-width: 450px;
        }
      }

      tr {
        border-bottom: 3px solid #f1f1f1;
        border-top: 3px solid #f9fafd;
      }
    }
  }
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  min-height: 100px;
  background: #FFFFFF;
  box-shadow: 0 0.15px 0.45px rgba(0, 0, 0, 0.11), 0 0.8px 1.8px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin: 16px 0 16px 0;
  .required {
    color: #0052cc;
    margin-left: 3px;
  }
  .main-mail {
    .input-container {
      padding: 0;
    }
  }
  .info-header {
    border-bottom: 0.5px solid #dfe1e6;
    height: 60px;
    width: 100%;
    padding: 7px 0 0 80px;
    display: flex;
    align-items: center;
    .icon {
      background: #0052CC;
      border-radius: 4px;
      width: 28px;
      height: 28px;
      padding: 2px;
      margin-right: 12px;
      i {
        color: #FFFFFF;
      }
    }
    h3 {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #152029;
    }
  }
  .info-body {
    width: 100%;
    padding: 16px 0 10px 80px;
    .profile-field {
      color: #38434C;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-family: $baseFont;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: auto;
      .phone-field {
        i {
          margin: 5px 0 0 4px;
        }

        p {
          margin-top: 6px;
        }
      }
      .email-field {
        i {
          margin: 9px 0 0 4px;
        }

        p {
          margin-top: 6px;
        }
      }
      .social-field {
        i {
          margin: 9px 0 0 4px;
        }
        p {
          margin-top: 6px;
        }
      }
      .date-of-birth {
        .react-datepicker {
          display: none !important;
        }
        .input {
          border-radius: 4px;
          height: 36px;
        }
        span {
          margin-left: -83px;
          margin-top: 6.5px;
          color: #757575!important;
          font-weight: 500;
          font-size: 16px;
        }
      }
      input, select, textarea{
        cursor: pointer;
        box-shadow: none;
      }

      .css-13cymwt-control {
        box-sizing: border-box;
        outline: 0!important;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        min-height: 36px!important;
        cursor: pointer;
        padding-right: 20px;
        &:hover {
          background: #F6F8FD;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(100% - 15px);
            transform: translateX(-50%);
            margin-top: -20px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6A7885;
          }
        }
        &:focus {
          border: 1px solid #0052cc!important;
        }
      }
      .css-1nmdiq5-menu {
        z-index: 3;
        min-width: 100%;
        width: max-content;
        right: 0;
        div div {
          height: 36px;
        }
      }
      .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .react-select__value-container {
        padding: 0;
      }
      .css-t3ipsp-control {
        border: 1px solid #0052cc!important;
        min-height: 36px;
        cursor: pointer;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: calc(100% - 15px);
          transform: translateX(-50%);
          margin-top: -19px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #6A7885;
        }
      }
      .css-t3ipsp-control:hover {
        border: 1px solid #0052cc!important;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: calc(100% - 15px);
          transform: translateX(-50%);
          margin-top: -19px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #6A7885;
        }
      }
      .profile-select {
        width: auto;
        white-space: nowrap;
        max-width: 500px;
        min-width: 228px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        font-family: $baseFont;
        line-height: 26px;
        border-radius: 4px;
        color: #38434C;
        border: none;
        margin-bottom: -5px;
        .css-9jq23d {
          padding: 0 0 0 6px!important;
          color: #38434c;
          cursor: pointer;
          font-family: Roboto,sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .css-1dyz3mf {
          display: flex;
        }
        ::placeholder {
          padding: 4px 12px;
          color: #AFB7BE;
        }
        ::-moz-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
        :-ms-input-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
        ::-ms-input-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
      }
      .react-select__control:hover {
        background: #F6F8FD;
      }
      .react-select__control:focus {
        border: 1px solid #0052cc!important;
      }
      .react-select__indicators {
        display: none;
      }
      .css-1jqq78o-placeholder {
        cursor: pointer;
      }
      .react-select__control {
        outline: none;
        border: none;
        cursor: pointer;
        margin: -4px -12px;
        min-height: 36px!important;
        height: 36px;
        display: flex;
        align-items: flex-start;
      }
      .react-select__control--menu-is-open {
        border: 1px solid #0052cc!important;
      }
      .react-select__control--is-focused {
        outline: none;
      }
      .react-select__menu {
        z-index: 3;
        white-space: nowrap;
        width: auto;
      }
      .react-select__control:focus {
        border: none;
        outline: none;
      }
      .react-select__menu {
        .react-select__option {
          height: 36px;
        }
      }
      .react-select__value-container {
        margin-right: 0;
        padding: 4px 12px;
        display: flex;
        align-items: flex-start;
        &:focus {
          border: 1px solid #0052cc;
        }
      }
      .css-t3ipsp-control, .css-t3ipsp-control:hover {
        outline: none!important;
        box-shadow: none!important;
      }
      .profile-field-title {
        margin-right: 72px;
        min-width: 202px;
        margin-top: 6px;
        display: flex;
      }
      .react-datepicker__input-container input {
        cursor: pointer;
        height: 25px!important;
        border: none;
        background: #ffffff;
      }
      .profile-field-value {
        display: flex;
        width: auto;
        position: relative;
        .css-1wy0on6 {
          display: none;
        }
        .css-1dimb5e-singleValue {
          color: #38434C;
        }
        #date-of-birth {
          border: 1px solid #FFFFFF;
          &:hover {
            background: #f6f8fd;
          }
          &:focus {
            border: 1px solid #0052cc;
          }
        }
        .horizontal-input {
          align-items: flex-start;
          display: flex;
          padding: 0;
        }
        .react-datepicker-wrapper {
          border: 0;
          display: inline-block;
          width: min-content;
          min-width: 135px;
          margin-top: 1px;
          margin-left: 10px;
          .react-datepicker__input-container input {
            padding: 8px 0 8px 37px!important;
            height: 36px!important;
            border-radius: 4px!important;
            margin-top: -1px;
            margin-left: -8px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }
        .calendar-today {
          pointer-events: none;
          position: absolute;
          margin-left: -206px;
          z-index: 1;
          cursor: pointer;
          top: 6px;
        }
        .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
          top: 22px!important;
        }
        .social-elepsis {
          margin-top: 10px;
          margin-left: -5px;
        }
        .input .input-container {
          margin-top: 0;
          position: relative;
          width: auto!important;
          height: 36px;
          textarea {
            height: 36px;
            overflow: hidden;
            background: none;
            cursor: pointer;
            box-sizing: border-box;
            max-width: 500px;
            min-width: 228px;
            white-space: nowrap!important;
            padding: 4px 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            font-family: $baseFont;
            line-height: 26px;
            border-radius: 4px;
            color: #38434C;
            border: 1px solid #FFFFFF;
            margin-top: 0;
            margin-right: 5px;
          }
          textarea:hover {
            background: #f6f8fd;
            max-height: 36px;
          }
          textarea:focus {
            border: 1px solid #0052cc;
            max-height: 36px;
          }
        }
      }
    }
  }
}

.input {
  position: relative;
  width: auto;
}

.contact-info {
  .ghost {
    color: #38434C;
  }
  .modal-content {
    width: 600px;
    border-radius: 4px;
  }
  .modal-body {
    padding: 24px 34px;
    .input .error {
      z-index: 1;
    }
  }
  .info-body {
    .profile-field-value .edit-icon {
      top: 4px;
    }
    .profile-field {
      .container-row {
        display: flex;
        flex-direction: column;
        .icon-add {
          font-weight: 700;
          font-size: 17px;
          color: $brandForegroundColor;
        }
      }
      .profile-field-value {
        display: flex;
        position: relative;
        width: auto;
        flex-direction: row;
        max-width: 500px;
        .copy-to-clipboard {
          z-index: 1;
          pointer-events: auto;
        }
        .icon-add {
          color: #0052cc!important;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          margin-top: 1px;
        }
        i {
          font-size: 18px;
          margin: 4px 0 0 0;
          border-radius: 50%;
          width: 28px;
          height: 28px;
          flex-shrink: 0;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background: #E6EEFA;
          }
        }
      }
    }
  }
  .phone-input-container {
    .row-container {
      margin: 5px 0;
    }
  }
  .row-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    .input {
      padding: 0;
    }
    .social-network-icon {
      height: 24px;
      margin-left: 5px;
      margin-right: 3px;
      margin-top: 10px;
    }
  }
  .profile-field {
    .material-icons {
      font-size: 14px;
    }
    .remove-button {
      pointer-events: auto!important;
      top: 1px;
      z-index: 1;
    }
    .add-button {
      background-color: transparent;
      text-align: left;
      margin-top: 5px;
      margin-left: 4px;
      margin-bottom: 7px;
    }
    .add-button:first-child {
      margin-top: 5px;
    }
    .add-button {
      color: $brandForegroundColor!important;
      cursor: pointer;
      display: flex;
      font-family: $baseFont;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .add-social-btn {
    padding: 10px 12px!important;
    background: #F6F8FD;
    border-radius: 4px;
    margin-top: 16px;
    width: max-content!important;
    margin-left: -13px;
    .medium-button {
      height: 24px;
    }
    p {
      font-family: $baseFont;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-right: 16px;
    }
  }
}

.info .info-body .profile-field .react-select__control--is-disabled {
  background-color: white;
  .react-select__single-value--is-disabled {
    color: $blackBaseColor;
  }
}
.css-16xfy0z-control {
  min-height: 36px;
  border: 1px solid #ffffff;
  background: #ffffff;
  border-color: #ffffff!important;
  background-color: #ffffff!important;
}
.css-olqui2-singleValue {
  color: #757575!important;
}
.status-history {
  .css-olqui2-singleValue {
    color: #38434C !important;
    background: #F2F2F2;
    padding: 3px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
    width: fit-content;
  }
}
.other-info {
  .error {
    position: absolute;
    display: flex;
    padding: 6px 12px;
    left: 6px;
    top: 99%;
    background: $red;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: $shadow2;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 16px;
      border: 7px solid transparent;
      border-top: none;
      border-bottom-color: $red;
    }

    svg {
      margin-right: 12px;
    }

    span {
      font-family: $baseFont;
      font-weight: 400;
      font-size: 14px;
      color: $redBaseColor;
    }
  }
}

.default-pointer {
  background: none!important;
  border: 1px solid #fff!important;
  cursor: default!important;
  color: #757575!important;
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  &:hover {
    background: none!important;
    border: 1px solid #fff!important;
  }
}
.disabled-pointer {
  cursor: url('../../../../../../assets/images/input/disabled-cursor.svg'), auto !important;
  .css-v7duua {
    display: none;
  }
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  &:hover {
    background: #fff!important;
    border: 1px solid #fff!important;
  }
}
.pad {
  .profile-field-title, .input-container, p {
    padding: 0;
    margin-top: 4px!important;
    margin-bottom: 4px!important;
  }
  i {
    margin-top: 8px!important;
  }
}
.field-pad-zero {
  padding: 0!important;
}
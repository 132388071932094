@import "./src/utils/fonts/roboto";
@import "./src/utils/variables/input";
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.input {
  .input-container {
    .react-select__control {
      font-size: 16px;
      font-weight: 400;
      font-family: $baseFont;
      background-color: $defaultBackgroundColor;
      border-radius: 4px;
      border: .5px solid $borderColor;
      width: 100%;
      min-height: 36px;
      transition: border .1s ease-out;
      max-width: 345px;
      &:focus {
        border: .5px solid $brandForegroundColor;
      }
    }

    .react-select__single-value {
      margin: 0;
      color: $blackBaseColor;
    }

    .react-select__value-container {
      padding: 0 12px;
    }
    .react-select__input-container {
      height: 36px;
      padding: 0;
      margin: 0;

      .react-select__input {
        height: 36px;
      }
    }
    .react-select__control--is-focused {
      border: .5px solid $brandForegroundColor;
      box-shadow: none;

      .react-select__indicator {
        color: $blackBaseColor !important;
      }

      &:hover {
        border-color: $brandForegroundColor;
      }
    }

    .react-select__indicator {
      color: rgba(138, 138, 149, 0.65);
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__menu {
      color: $blackBaseColor;
      border-radius: 4px;
      font-family: $baseFont;
      text-transform: none;
      z-index: 2;
    }

    .react-select__option--is-focused {
      background-color: #f5f5f5 !important;
      color: $blackBaseColor;
    }

    .react-select__option--is-disabled {
      color: $defaultForegroundDisabledColor;
    }

    .react-select__option--is-selected {
      background-color: var(--blue-lighter-2, #F6F8FD);
      color: var(--black-black-dark, #152029);
    }

    .react-select__multi-value__remove {
      transition: background-color .1s ease-out, color .1s ease-out;

      &:hover {
        background-color: $redBaseColor;
        color: #FFF;
      }
    }

    .react-select__multi-value__label {
      font-size: 12px;
    }

    .react-select__placeholder {
      font-size: 16px;
      margin: 0;
      color: $defaultForegroundDisabledColor;
    }

    .select-is-not-valid {
      .react-select__control {
        border: 2px solid $redBaseColor;
      }
    }
  }
}
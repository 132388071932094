@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.country-filter {
  .react-select__control {
    border: none!important;
    background: $backgroundColor!important;
    font-family: $baseFont;
    font-size: 18px!important;
    font-weight: 400!important;
  }
  .css-1dimb5e-singleValue {
    color: #003563!important;
  }
  .react-select__value-container {
    padding: 0!important;
    margin-left: -10px;
  }
  label {
    display: none!important;
  }
  .react-select__menu {
    z-index: 3;
    white-space: nowrap;
    width: auto;
  }
}
.holidays-content {
  .absolute {
    margin-top: -25px;
  }
  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    top: 20px!important;
  }
}
.sub-header-filter-block {
  list-style-type: none;
  li {
    font-family: $baseFont;
    color: #6A7885;
    font-size: 14px;
    font-weight: 500;
    background: #FFFFFF;
    padding: 6px;
    cursor: pointer;
  }
  .active {
    background: #E6EEFA;
    color: #0052CC;
  }
}

.sub-content-main {
  .content {
    padding-top: 44px;
  }
  .vert-menu-btn {
    background: none;
    .material-icons {
      cursor: pointer!important;
    }
  }
  .check-success {
    color: #2DAE5D;
  }
  .center {
    text-align: center!important;
  }
  .zero-padding {
    padding: 0!important;
  }
  .count-margin-fix {
    margin-left: -20px;
  }
  .modal-content {
      .what-to-delete {
        display: contents!important;
      }
      p {
        text-align: left;
      }
  }

  .absolute {
    margin-left: 75px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 5px;
    margin-top: 0;
    .material-icons {
      margin: 5px 0;
      font-size: 20px!important;
      padding: 0 2px!important;
      cursor: pointer!important;
    }
  }
  .holiday-menu {
    background-color: $backgroundColor;
    display: flex;
    list-style-type: none;
    margin-left: -10px;
    margin-top: -7px;
    position: fixed;
    width: calc(100% - 395px);
    z-index: 1;
    padding-top: 10px;
    li {
      font-family: $baseFont;
      color: #38434C;
      font-size: 16px;
      font-weight: 500;
      padding: 11px;
      cursor: pointer;
    }
    .active {
      color: #0052CC;
      border-bottom: 3px solid #0052CC;
    }
  }
}
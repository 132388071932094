@import "./src/utils/fonts/roboto";
@import "./src/utils/variables/input";
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.horizontal-input {
  display: flex;
  padding: 10px 0;
  align-items: flex-start;

  label, .input-container {
    padding: 0 15px;
  }

  label {
    align-items: center;
    display: flex;
    justify-content: end;
    min-width: 155px;
  }
  .input-container {
    margin-top: 0 !important;
    input {
      height: 36px !important;
    }
  }

  .error {
    left: 20px !important;
    top: 89% !important;
    z-index: 99;
  }
}

.input:disabled {
  background-color: #ddd!important;
}

.input {
  position: relative;
  width: 100%;

  span {
    font-family: $baseFont;
    font-size: 16px;
    font-weight: 400;
  }

  label {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 41px;
    color: $blackBaseColor;
    white-space: nowrap;
    span {
      color: $brandForegroundColor;
    }
  }

  .password {
    .visibility {
      right: 12px;
    }

    input {
      padding-right: 46px !important;
    }
  }

  .input-container {
    position: relative;
    width: 100%;
    margin-top: 8px;
  }

  .error {
    position: absolute;
    display: flex;
    padding: 6px 12px;
    left: 6px;
    top: 99%;
    background: $red;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: $shadow2;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 100%;
      left: 16px;
      border: 7px solid transparent;
      border-top: none;
      border-bottom-color: $red;
    }

    svg {
      margin-right: 12px;
    }

    span {
      font-family: $baseFont;
      font-weight: 400;
      font-size: 14px;
      color: $redBaseColor;
    }
  }
}
@import "./src/utils/variables/base";

.date-time-picker {
  .react-datepicker {
    border: none !important;
    border-radius: 12px !important;
    color: #152029 !important;
    display: flex;
    font-family: $baseFont !important;
    position: relative;
    width: 390px !important;
    height: auto !important;
    padding-bottom: 0 !important;
    font-size: 18px !important;
    background: linear-gradient(100.6deg, #FFFFFF 0.61%, #F7FAFF 54.71%, #F4F6FA 100%) !important;
    box-shadow: 0 76.2792px 116.538px rgb(0 39 140 / 13%);

    .react-datepicker__day-name {
      color: #38434c !important;
      display: inline-block;
      line-height: 33px !important;
      margin: 0.166rem !important;
      text-align: center !important;
      width: 33px !important;
    }
  }

  .react-datepicker__navigation-icon {
    font-size: 20px !important;
    position: relative !important;
    top: 2px !important;
    width: 0 !important;
  }

  .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name {
    color: #152029;
    display: inline-block;
    margin: 0 !important;
    text-align: center;
    width: 38px !important;
    padding: 0 !important;
    line-height: 38px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__day--weekend, .react-datepicker__day--keyboard-selected, .react-datepicker__day--today {
    color: #152029 !important;
    background-color: transparent !important;
  }

  .react-datepicker__month-container {
    float: none !important;
    margin-right: 12px !important;
  }

  .react-datepicker__navigation--previous {
    top: 9px !important;
    left: 40px !important;
  }

  .react-datepicker__navigation--next {
    top: 9px !important;
    position: relative;
    left: 235px !important;
  }

  .react-datepicker__input-container {
    input {
      background-color: #fcfdfe !important;
      border: 0.5px solid #c2cad1 !important;
      border-radius: 4px !important;
      color: #38434c;
      font-family: Roboto, sans-serif !important;
      font-size: 16px;
      font-weight: 400;
      height: 44px !important;
      padding: 8px 12px !important;
      transition: border .1s ease-in-out, box-shadow .1s ease-in-out;
      width: 100% !important;
    }
  }

  .react-datepicker__day--selected:hover, .react-datepicker__day--selected {
    background-color: #E6EEFA !important;
    color: #0044AA !important;
    border-radius: 25px !important;
  }

  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 25px !important;
  }

  .react-datepicker__header {
    padding: 15px 0 0 !important;
    position: relative;
    text-align: center;
    border-radius: 0;
    border-bottom: 0 !important;
    background: none !important;
    border-bottom: 0;
    box-shadow: none;

    .react-datepicker__day-name {
      color: #6A7885 !important;
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
    }

    .react-datepicker__current-month {
      color: #003563 !important;
      margin-top: 0 !important;
      font-family: $baseFont !important;
      font-style: normal;
      font-weight: 500;
      font-size: 20px !important;
      line-height: 23px !important;
      margin-left: -5px !important;
      padding-bottom: 12px !important;
      padding-top: 3px !important;
    }

    .react-datepicker-time__header {
      color: #003563 !important;
      font-family: $baseFont !important;
      font-style: normal;
      font-weight: 500;
      font-size: 20px !important;
      line-height: 23px !important;
      margin-left: -5px !important;
      padding-bottom: 12px !important;
      padding-top: 3px !important;
    }
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      background: transparent !important;
      margin-bottom: 20px !important;

      .react-datepicker__time-box {
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            color: #152029;
            font-weight: 400 !important;
            font-size: 14px !important;
          }

          li.react-datepicker__time-list-item--selected {
            background-color: #E6EEFA !important;
            color: #0044AA !important;
          }
        }
      }
    }
  }
}
@import "./src/utils/variables/input";
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.data-selector {
  .label-week {
    color: #6A7885;
    font-size: 14px;
  }

  .date-selector {
    display: flex;
    align-items: center;
    .horizontal-input .input-container input {
      display: block;
      height: 28px;
      margin: 0 8px 0 10px;
    }
    .react-select__value-container {
      padding: 0 10px;
      font-size: 14px;
      height: 28px;
    }
    .react-select__single-value {
      color: #38434c;
      margin: 0;
      width: 90px;
      padding-bottom: 10px;
    }
    .react-select__control {
      height: 28px;
      min-height: 28px;
    }
    .css-1wy0on6 {
      width: 24px;
    }
    .react-select__indicator {
      padding: 0!important;
    }
  }
  .month {
    width: 108px;
    display: inline-flex;
    margin: 0 8px 0 10px;
  }

  .weekday {
    display: inline-flex;
    margin: 0 0 0 8px;
    width: min-content;
  }

  #week {
    background-color: #fcfdfe;
    border: 0.5px solid #c2cad1;
    border-radius: 4px;
    font-family: $baseFont;
    font-size: 14px;
    font-weight: 400;
    height: 28px!important;
    margin-left: 8px;
    min-height: 28px;
    padding: 0 0 0 6px;
    width: 32px;
  }

  #week:after, #week:before {
    content: "\25B2";
  }

  .month-icon {
    line-height: 24px;
  }
}
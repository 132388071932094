@import "./src/utils/variables/base";
@import "./src/utils/variables/input";

.filter-container {
  display: flex;
  width: 240px;
  height: 348px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  border-radius: 4px;
  border: 5px solid var(--blue-light, #E6EEFA);
  background: #E6EEFA;
  box-shadow: 0 3.200000047683716px 7.199999809265137px 0 rgba(0, 0, 0, 0.13), 0 0.6000000238418579px 1.7999999523162842px 0 rgba(0, 0, 0, 0.11);
  font-family: $baseFont;

  position: absolute;
  top: 55px;
  right: 8px;

  .filter-group {
    display: flex;
    width: 100%;
    padding: 12px 0 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: var(--background, #F9FAFD);
    margin-bottom: 2px;

    .header {
      color: var(--black-black-dark, #152029);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .body {
      .filter-checkbox .checkbox-container span {
        color: var(--black-black-dark, #152029);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .filter-checkbox {
        display: flex;
        align-items: center;

        .only-input-container {
          display: flex;
          align-items: center;
          position: relative;
        }
      }

      .filter-checkbox .checkbox-container input[type="checkbox"] {
        appearance: none;
        -moz-appearance: none;  /* for Firefox */
        -webkit-appearance: none; /* for older versions of Chrome and Safari */
      }

      .filter-checkbox .checkbox-container input[type="checkbox"]::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        position: absolute;
        top: 5px;
        left: 0;
        background: transparent;
        border: 2px solid #ccc;
      }

      .filter-checkbox .checkbox-container input[type="checkbox"]:checked::before {
        width: 18px;
        height: 18px;
        border: none;
        background: $brandForegroundColor;
      }

      .filter-checkbox .checkbox-container input[type="checkbox"]:checked::after {
        content: "";
        display: block;
        width: 4px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        position: absolute;
        transform: rotate(45deg);
        top: 6px;
        left: 6px;
      }

      .react-select__control {
        border: none;
        background-color: transparent;
        min-height: 0;
        margin-top: -7px;
        margin-left: -2px;
        width: 200px;
      }

      .react-select__control--is-focused {
        border: none;
        box-shadow: none;
        background-color: transparent;
        min-height: 0;
      }

      .react-select__single-value {
        font-size: 14px;
      }

      .react-select__option {
        font-size: 14px;
      }

      .react-select__indicator-separator {
        width: 0;
      }

      .react-select__indicator {
        padding: 0;
      }

      .react-select__option--is-selected {
        background-color: var(--blue-lighter-2, #F6F8FD);
        color: var(--black-black-dark, #152029);
      }

      .react-select__value-container {
        padding: 0;
      }

      .react-select__dropdown-indicator {
        padding: 0 8px;
        position: relative;
        box-sizing: content-box;
      }

      .react-select__dropdown-indicator::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--black-black-dark, var(--grey-grey-border, #C2CAD1));
        top: 50%;
        left: calc(50% - 5px);
        transform: translate(-50%, -50%);
      }

      .react-select__dropdown-indicator svg {
        display: none;
      }
    }
  }

  .filter-footer {
    display: flex;
    padding: 12px 16px 8px 24px;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
    border-radius: 0 0 4px 4px;
    background: var(--blue-light, #E6EEFA);
  }
}
@import "../../../../../utils/variables/base";
@import "../../../../../utils/variables/input";

.first-page-modal-closed {
  display: none;
}
.first-page-modal-opened {
  display: flex;
}

.first-page-modal {
  width: 888px;
  height: 100%;
  flex-direction: column;
  font-family: $baseFont;
  font-style: normal;
  color: $blackBaseColor;
  align-items: center;
}

.first-page-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 28px;
  width: 888px;
  min-height: 447px;
  background: #FFFFFF;
  box-shadow: 0 0.15px 0.45px rgba(0, 0, 0, 0.11), 0 0.8px 1.8px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
  .first-page-modal-title {
    font-size: 18px;
    line-height: 150%;
    font-weight: 400;
    width: 760px;
    height: 35px;
    left: 64px;
    top: 16px;
    padding-left: 60px;
    color: $blackDark;
  }
  .first-page-modal-line {
    content: '';
    width: 760px;
    height: 0.5px;
    background: #DFE1E6;
    margin-bottom: 5px;
  }
  .first-page-modal-body {
    width: 620px;
    margin-right: 50px;
    .input .input-container {
      .react-select__control {
        max-width: 378px;
      }
    }
    .horizontal-input label {
      justify-content: start;
      max-width: 320px;
      width: 320px;
    }
  }
  .first-page-modal-footer {
    width: 620px;
    text-align: right;
  }

  .first-page-modal-footer > * + * {
    margin-left: 10px;
  }

  .button {
    display: inline-block;
    margin-right: 40px;
  }
}

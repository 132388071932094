$backgroundColor: #F1F5F8;
$brandForegroundColor: #0052CC;
$blackBaseColor: #38434C;
$redBaseColor: #FF5961;
$red: #FCEAEC;
$lightGreyBase: #e5e5e5;
$blackDark: #152029;
$greyBorder: #C2CAD1;
$greyLight: #F2F2F2;
$greyWhite: #FCFDFE;
$greyGrey: #DFE1E6;
$blueLighter2: #F6F8FD;
$blueLight: #E6EEFA;
$blueLightBlue: #F3F8FC;

$baseFont: 'Roboto', sans-serif;
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.forbidden {
  margin-top: 76px;
  color: #6A7885;
  align-items: center;
  text-align: center;
  font-family: $baseFont;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 56px;
    margin-bottom: 12px;
  }
}
@import './src/utils/variables/base';

@media (max-width: 1360px) {
  nav {
    padding: 88px 10px;
    .material-symbols-outlined {
      font-size: 24px;
      width: 24px;
      height: 24px;
    }
  }
}

@media (min-width: 1361px) {
  nav {
    padding: 86px 10px;
    .material-symbols-outlined {
      font-size: 28px;
      width: 28px;
      height: 28px;
    }
  }
}

.nav-hidden {
  width: 58px;
  flex: 0 0 58px;
}
nav {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 58px;
  background-color: #FFFFFF;
  color: #FFF;
  z-index: 1;

  .material-symbols-outlined {
    color: #6A7885;
    flex-shrink: 0;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    text-decoration: none;
    position: relative;

    ::before {
      content: "";
      width: 44px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border-radius: 3px;
      z-index: -1;
      transition: background-color 0.3s ease;
    }

    &:hover {
      ::before {
        background-color: #E6EEFA;
      }

      .material-symbols-outlined {
        color: #0052CC;
      }
    }
  }
  .new-requests-counter {
    background: #0044AA;
    border: 1px solid #E6EEFA;
    border-radius: 50%;
    color: #FFFFFF;
    font-family: $baseFont;
    text-decoration: none;
    padding: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    position: absolute;
    margin-left: 20px;
    margin-top: -60px;
    min-width: 20px;
    text-align: center;
    pointer-events: none;
  }
}

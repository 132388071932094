@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.sub-header-filter-block-calendar {
  position: absolute;
  right: 0;
  margin-left: calc(100% - 700px)!important;
  margin-top: -42px;
  z-index: 2;
}

.calendar {
  height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 8;
  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    box-shadow: 0 0.8px 1.8px 0 rgba(0, 0, 0, 0.13), 0 0.15px 0.45px 0 rgba(0, 0, 0, 0.11);
  }
  .thead-shadow-scrolling {
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.11), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.07);
  }
  .modal-header {
    background-color: #F9FAFD;
    color: #152029;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 15px 35px;
    box-shadow: inset 0px -0.5px 0px #C2CAD1;
  }

  .types-link {
    margin: 10px 0;
    a {
      color: #0052cc;
    }
  }
  .balance-field {
    .css-b62m3t-container {
      width: 100%!important;
    }
  }
  .react-select__control {
    max-width: 100%!important;
  }
  .icon-end {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: 18px;
    pointer-events: none;
    font-size: 21px;
    color: #AFB7BE;
    right: 9%;
  }
  .icon-start {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: 17px;
    pointer-events: none;
    font-size: 21px;
    color: #AFB7BE;
    left: 43%;
  }
  .modal-header .material-icons {
    color: #6A7885;
    cursor: pointer;
  }
  .modal-footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 12px 24px;
    background: #FCFDFE;
    box-shadow: inset 0 0.5px 0 0 #C2CAD1;

  }
  .request-modal {
    .modal-content {
      min-width: 497px!important;
      width: auto!important;
      max-width: min-content!important;
    }
    .modal-body {
      padding: 0;
      .available-block {
        display: flex;
        justify-content: space-between;
        span:last-child {
          color: #6A7885;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #38434C;
        font-family: $baseFont;
        padding-top: 10px;
        span {
          font-weight: 400;
        }
        &:first-child {
          padding-top: 0;
        }
      }
      .date-block {
        display: flex;
        flex-direction: row;
        .react-datepicker__navigation {
          top: 20px !important;
        }
        .react-datepicker__day--disabled {
          color: #ccc !important;
        }
      }
      .horizontal-input label {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        min-width: auto;
        width: max-content;
        margin-right: 8px;
      }
      .css-b62m3t-container, .css-b62m3t-container {
        position: relative;
        box-sizing: border-box;
        min-width: 204px;
        width: max-content;
        margin-bottom: 10px;
      }
      .horizontal-input .input-container, .horizontal-input label {
        padding: 0;
      }
    }
  }
}
.sub-content-block {
  .trending-flat {
    padding-top: 2px!important;
    cursor: auto!important;
    transform: scaleX(-1);
  }
  .request-btn {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    height: 24px;
    width: max-content;
    padding: 0 12px;
    float: left;
    margin-right: 18px;
    align-items: center;
    display: flex;
  }
  .modal-inner {
    pointer-events: none;
  }
  .request-modal-footer {
    background: #fcfdfe;
    box-shadow: inset 0 0.5px 0 0 #c2cad1;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 12px 24px;
    margin: 25px -36px -25px -35px;
    .request-btn {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      height: 32px;
      width: max-content;
      padding: 0 12px;
      float: left;
      margin-right: 18px;
      align-items: center;
      display: flex;
    }

    .Approve {
      box-shadow: 0 0.15px 0.45px rgba(0,0,0,.11), 0 0.8px 1.8px rgba(0,0,0,.13);
      color: #0052cc;
      font-family: $baseFont;
      font-weight: 500;
      transition: box-shadow .1s ease-in-out;
      border: 1px solid #0052cc;
      &:hover {
        color: #0052cc;
        background: #E6EEFA;
        border: 1px solid #0052cc;
      }
    }

    .Reject {
      color: #6A7885;
      border: 1px solid #C2CAD1;
      &:hover {
        background: #C2CAD1;
      }
    }

    .Cancel {
      color: #6A7885;
      border: 1px solid #6A7885;
    }
  }
  .calendar-data-table {
    width: 100%;
    border-collapse: collapse;
    .without-avatar {
      background-color: #9aa4be;
      border: 1px solid #e6ecf1;
      border-radius: 25px;
      display: inline-grid;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      p {
        color: #fff;
        font-family: Roboto,sans-serif;
        height: 24px;
        line-height: 24px;
        text-align: center;
        width: 23px;
      }
    }
    .with-avatar {
      margin-right: 8px;
      display: inline-grid;
      img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }
    }
    th:nth-child(1) {
      padding: 0!important;
    }
    .first-column {
      border-right: 0.5px solid #DFE1E6;
      padding: 16px 24px;
      height: 55px;
    }
    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      height: 56px;
      color: #6A7885;
      padding: 5px 3px;
      background: #FFFFFF;
      text-align: center;
      max-width: 50px;
      width: 50px;
      min-width: 34px;
      .day {
        color: #38434C;
      }
      .weekday {
        font-size: 12px;
        font-weight: 400;
      }
      &:first-child {
        min-width: 200px;
        padding: 16px 24px;
        text-align: left;
        border-radius: 4px 0 0 0;
      }
      &:last-child {
        border-radius: 0 4px 0 0;
      }
      a {
        padding: 0 10px;
      }
    }
    .today {
      background: #4088f8;
      color: #fff;
      border-radius: 4px;
      margin: -22px 0px;
      padding: 6px;
      .day {
        color: #FFFFFF;
      }
    }
    .weekend {
      background: #F5F5F5;
    }
    .simple-day {
      background: #FFFFFF;
    }
    .react-tooltip {
      background: #fff!important;
      box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
      color: #38434c!important;
      font-family: Roboto,sans-serif;
      margin-top: -5px;
      max-width: 300px;
      width: max-content;
      text-align: left;
      margin-left: 0!important;
      opacity: 1!important;
      padding: 8px!important;
      z-index: 5;
      position: absolute;
      height: max-content;
      span {
        padding: 0;
        height: max-content;
      }
      .additional-paid-time-off {
        padding: 5px;
        margin-top: -7px;
      }
      .popup-main-text {
        font-size: 14px;
        font-weight: 500;
        color: #003563;
        margin-top: 6px;
      }
      .popup-main-holiday-text {
        color: #003563;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-left: 5px;
        white-space: normal;
      }
      .company, .holiday-text {
        background: #e6eefa;
        border-radius: 4px;
        height: 20px;
        margin-bottom: 7px;
        padding: 0 6px;
        width: max-content;
      }
      .employee-block {
        display: flex;
        flex-direction: row;
        align-content: center;
        height: 36px;
      }
      .type-block {
        align-content: center;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        height: 24px;
        padding: 0 5px;
        margin-bottom: 7px;
        width: max-content;
        span {
          height: 18px;
          margin-top: 3px;
          margin-left: 5px;
        }
        svg {
          height: 18px;
          width: 18px;
          margin-top: 3px;
        }
      }
      .not-confirmed {
        background: #f2f2f2;
        color: #38434c;
        font-size: 14px;
        height: 20px;
        padding: 1px 5px;
        border-radius: 4px;
      }
      .replacing {
        color: #38434c;
        font-size: 14px;
        height: 20px;
        padding: 1px 5px;
        border-radius: 4px;
      }
      .working-hours {
        font-weight: 500;
        font-size: 16px;
      }
      .schedule-block {
        display: flex;
        white-space: nowrap;
        font-size: 14px;
        margin-top: 5px;
        span {
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
        };
        i {
          font-size: 22px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }
      .tool-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2px 0;
        i {
          display: flex;
          justify-content: end;
          width: 20px;
          font-size: 18px;
          span {
            font-weight: 600;
          }
        }
        .date-inner-container {
          margin-left: 4px;
          display: flex;
          margin-bottom: 2px;
        }
      }
    }
    td:nth-child(1) {
      border-right: 0.5px solid #DFE1E6;
    }
    .today-border {
      border-left: 0.5px solid rgba(0, 82, 204, 0.50);
      border-right: 0.5px solid rgba(0, 82, 204, 0.50);
      background: #F9FAFD;
    }
    .last-today-border {
      border-bottom: 0.5px solid rgba(0, 82, 204, 0.50);
    }
    .first-today-border {
      border-top: 0.5px solid rgba(0, 82, 204, 0.50);
    }
    td {
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
      border-radius: 2px;
      background: #FFF;
      padding: 0;
      height: 40px;
      width: 34px;
      border: none;
      color: #152029;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      .event-style {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        .event-icon {
          display: flex;
          align-items: center;
        }
      }
      a {
        text-decoration: none;
      }
      a, a:visited {
        color: #152029;
      }
      &:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
        max-width: initial;
        padding: 16px 24px;
        width: auto;
      }
    }
  }
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.sub-header-filter-block-request {
  position: absolute;
  margin-left: calc(100% - 935px)!important;
  margin-top: -42px;
  right: 0;
  z-index: 2;
}

.requests {
  height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 8;
  .request-icon {
    float: left;
    margin-right: 5px;
  }
  .absolute {
    align-items: flex-start;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
    display: flex;
    flex-direction: column;
    margin-left: 0;
    width: 132px;
    padding: 4px;
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    color: #38434C;
    margin-top: 8px;
    label {
      display: flex;
      align-items: center;
    }
    input {
      height: 15px;
      width: 15px;
      margin: 8px 10px 9px 5px;
    }
  }
  .vert-menu-btn {
    background: none;
  }
  .modal-header {
    background-color: #F9FAFD;
    color: #152029;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 15px 35px;
    box-shadow: inset 0px -0.5px 0px #C2CAD1;
  }
  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    box-shadow: 0 0.8px 1.8px 0 rgba(0, 0, 0, 0.13), 0 0.15px 0.45px 0 rgba(0, 0, 0, 0.11);
  }
  .thead-shadow-scrolling {
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.11), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.07);
  }
  .status-column {
    display: flex;
    p {
      color: #38434c;
      font-family: Roboto,sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 4px 12px 0 0;
    }
  }

  .types-link {
    margin: 10px 0;
    a {
      color: #0052cc;
    }
  }
  .balance-field {
    .css-b62m3t-container {
      width: 100%!important;
    }
  }
  .react-select__control {
    max-width: 100%!important;
  }
  .icon-end {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: 18px;
    pointer-events: none;
    font-size: 21px;
    color: #AFB7BE;
    right: 9%;
  }
  .icon-start {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: 17px;
    pointer-events: none;
    font-size: 21px;
    color: #AFB7BE;
    left: 43%;
  }
  .modal-header .material-icons {
    color: #6A7885;
    cursor: pointer;
  }
  .modal-footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 12px 24px;
    background: #FCFDFE;
    box-shadow: inset 0 0.5px 0 0 #C2CAD1;

  }
}
.sub-content-block {
  .trending-flat {
    padding-top: 2px!important;
    cursor: auto!important;
    transform: scaleX(-1);
  }
  .request-btn {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    height: 24px;
    width: max-content;
    padding: 0 12px;
    float: left;
    margin-right: 18px;
    align-items: center;
    display: flex;
  }

  .Approve {
    color: #0052cc;
    border: 1px solid #0052cc;
    i {
      width: 18px;
      color: #0052cc;
    }
    &:hover {
      color: #0052cc;
      background: #E6EEFA;
      border: 1px solid #0052cc;
      i {
        color: #0052cc;
      }
    }
  }

  .Reject {
    color: #6A7885;
    border: 1px solid #C2CAD1;
    &:hover {
      background: #C2CAD1;
    }
  }

  .Cancel {
    color: #6A7885;
    border: 1px solid #6A7885;
  }
  .modal-inner {
    pointer-events: none;
  }
  .requests-table {
    width: 100%;
    border-collapse: collapse;
    .without-avatar {
      background-color: #9aa4be;
      border: 1px solid #e6ecf1;
      border-radius: 25px;
      display: inline-grid;
      margin-right: 8px;
      p {
        color: #fff;
        font-family: Roboto,sans-serif;
        line-height: 31px;
        text-align: center;
        width: 30px;
        height: 30px;
      }
    }
    .with-avatar {
      margin-right: 8px;
      display: inline-grid;
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      text-align: left;
      height: 56px;
      color: #6A7885;
      padding: 16px 24px;
      background: #FFFFFF;
      white-space: nowrap;
      &:last-child {
        width: 155px;
      }
      a {
        padding: 0 10px;
      }
    }
    .new-request {
      background: #F6F8FD;
      .with-avatar, .without-avatar {
        margin-left: -3px;
      }
      &:first-child {
        border-left: 3px solid #0073D6;
      }
    }
    .old-request {
      background: #F2F2F2!important;
    }
    td {
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
      border-radius: 2px;
      background: #FFF;
      padding: 6px 24px;
      border: none;
      color: #152029;
      min-width: 155px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 780px;
      .status-block {
        display: flex;
        span {
          color: #6a7885;
          font-family: Roboto,sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          display: contents;
          height: auto;
          line-height: 24px;
          padding: inherit;
        }
        p {
          color: #38434C;
          font-family: $baseFont;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-left: 3px;
          a, a:visited {
            color: #38434C;
            text-decoration: none;
          }
        }
        .cancelled-border {
          border: 1px solid #6A7885;
        }
      }
      a {
        text-decoration: none;
      }
      a, a:visited {
        color: #152029;
      }
      .New {
        height: 24px;
        font-family: $baseFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0052CC;
        background: #E6EEFA;
        border-radius: 20px;
        width: max-content;
        padding: 0 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
      }
      .Approved {
        height: 24px;
        font-family: $baseFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2DAE5D;
        background: #EEFBF3;
        border-radius: 20px;
        width: max-content;
        padding: 0 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
      }
      .Rejected {
        height: 24px;
        font-family: $baseFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #E12D39;
        background: #FCEAEC;
        border-radius: 20px;
        width: max-content;
        padding: 0 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
      }
      .Cancelled {
        height: 24px;
        font-family: $baseFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #38434C;
        background: #F2F2F2;
        border-radius: 20px;
        width: max-content;
        padding: 0 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
      }
      .material-icons {
        padding: 0 5px;
        font-size: 20px;
        cursor: pointer;
      }
      &:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
      &:last-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #38434C;
        font-size: 14px;
        font-weight: 400;
        font-family: $baseFont;
        cursor: pointer;
      }
    }
    tr {
      border-bottom: 0.5px solid #DFE1E6;
      border-top: 0.5px solid #DFE1E6;
    }
  }
}
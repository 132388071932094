@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.job-info {
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0 0.15px 0.45px rgba(0, 0, 0, 0.11), 0 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    margin: 16px 0 16px 0;
    .info-header {
      border-bottom: 0.5px solid #dfe1e6;
      height: 60px;
      width: 100%;
      padding: 7px 0 0 80px;
      display: flex;
      align-items: center;
      .icon {
        background: #0052CC;
        border-radius: 4px;
        width: 28px;
        height: 28px;
        padding: 2px;
        margin-right: 12px;
        i {
          color: #FFFFFF;
        }
      }
      h3 {
        font-family: $baseFont;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #152029;
      }
    }
    .info-body {
      width: 100%;
      padding: 16px 0 10px 80px;
      .profile-field {
        color: #38434C;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 0;
        font-family: $baseFont;
        display: flex;
        flex-direction: row;
        align-items: center;
        input, select, textarea{
          cursor: pointer;
          box-shadow: none;
        }
        .css-13cymwt-control {
          box-sizing: border-box;
          outline: 0!important;
        }
        .react-select__indicators {
          display: none;
        }
        .css-1jqq78o-placeholder {
          cursor: pointer;
        }
        .react-select__control {
          outline: none;
          border: none;
          min-width: 200px;
        }
        .react-select__control--is-focused {
          border: none;
          outline: none;
        }
        .react-select__menu {
          z-index: 3;
          white-space: nowrap;
          width: auto;
        }
        .react-select__control:focus {
          border: none;
          outline: none;
        }
        .profile-field-title {
          margin-right: 72px;
          min-width: 202px;
        }
        .job-info-select {
          margin-left: 9px;
        }
        .css-hlgwow {
          margin-top: -2px;
        }
        #employment-status-first, #employment-status-second {
          border-radius: 4px;
        }
        #employment-status-first:hover, #employment-status-second:hover {
          background: #f6f8fd;
        }
        #employment-status-first:focus, #employment-status-second:focus {
          border: 1px solid #0052cc;
        }
        .profile-field-value {
          .react-datepicker__input-container {
            display: inline-block;
            position: relative;
            width: 85px;
          }
          .user-link {
            input {
              border-radius: 4px;
              height: 36px;
              margin-top: -7px;
            }
          }
          .react-datepicker__input-container input {
            cursor: pointer;
            height: 25px!important;
            border: none!important;
            background: #ffffff!important;
            padding-top: 0!important;
            padding-left: 0!important;
            margin-left: 10px;
          }
          display: flex;
          .material-symbols-outlined {
            color: #6a7885;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          .calendar {
            margin-left: -205px;
            padding-bottom: 0;
            overflow: hidden;
          }
          .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
            top: 22px!important;
          }
          .input .input-container {
            margin-top: 0;
            position: relative;
            width: 100%;
            height: 24px;
            textarea {
              height: 28px;
              border: none;
              overflow: hidden;
              background: #ffffff;
              padding: 0;
              cursor: pointer;
            }
          }
          .disabled-block {
            position: absolute;
            width: 135px;
            height: 30px;
            z-index: 1;
          }
          .hire-date {
            display: flex;
            flex-direction: row;
            width: 150px;
            height: 30px;
            align-items: center;
            .react-datepicker__input-container input:disabled {
              color: #757575!important;
              cursor: default;
            }
            .react-datepicker-wrapper {
              border: 0;
              display: inline-block;
              margin-left: 0;
              margin-top: -4px;
              min-width: 135px;
              width: min-content;
              input {
                height: 36px!important;
                padding: 4px 0!important;
                width: 85px!important;
              }
            }
          }
          .content-copy {
            font-size: 18px;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 14px;
            &:hover {
              background: #E6EEFA;
            }
          }
          .check-element {
            color: #4CAF50;
            margin-left: 10px;
            cursor: pointer;
          }
          .rehire-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 4px;
            gap: 12px;
            width: 80px;
            height: 24px;
            background: #0052CC;
            border-radius: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            font-family: $baseFont;
            color: #FFFFFF;
            cursor: pointer;
            margin-left: 15px;
          }
          .status-error {
            position: absolute;
            display: flex;
            padding: 6px 12px;
            left: 0;
            top: 99%;
            background: $red;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            box-shadow: $shadow2;
            z-index: 2;
            width: 425px;
            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              bottom: 10px;
              left: -11px;
              transform: translateY(-50%) rotate(-90deg);
              border: 7px solid transparent;
              border-top: none;
              border-bottom-color: $red;
            }
            svg {
              margin-right: 12px;
            }
            span {
              font-family: $baseFont;
              font-weight: 400;
              font-size: 14px;
              color: $redBaseColor;
            }
          }
          .add-status-button {
            background: transparent;
            border: none;
            color: #0052CC;
            cursor: pointer;
            font-size: 16px;
            text-decoration: none;
            outline: none;
            margin-bottom: 2px;
            margin-left: 2px;
            margin-top: -10px;
          }
          .plus-sign {
            margin-right: 5px;
            font-size: 20px;
          }
        }
      }
      .css-13cymwt-control {
        box-sizing: border-box;
        outline: 0!important;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        min-height: 36px!important;
        cursor: pointer;
        &:hover {
          background: #F6F8FD;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: calc(100% - 15px);
            margin-top: -21px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6A7885;
          }
        }
        &:focus {
          border: 1px solid #0052cc!important;
        }
      }
      .css-1wy0on6 {
        display: none;
      }
      .css-1nmdiq5-menu {
        z-index: 3;
        min-width: 100%;
        width: max-content;
        right: 0;
        div div {
          height: 36px;
        }
      }

      .css-16xfy0z-control {
        min-height: 36px;
        border: 1px solid #ffffff;
        background: #ffffff;
      }
      .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .react-select__value-container {
        padding: 0;
      }
      .css-t3ipsp-control {
        border: 1px solid #0052cc!important;
        min-height: 36px;
        cursor: pointer;
        padding-right: 20px;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: calc(100% - 15px);
          margin-top: -21px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #6A7885;
        }
      }
      .css-t3ipsp-control:hover {
        border: 1px solid #0052cc!important;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: calc(100% - 15px);
          margin-top: -21px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #6A7885;
        }
      }
      .profile-select {
        width: auto;
        white-space: nowrap;
        max-width: 500px;
        min-width: 228px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        font-family: $baseFont;
        line-height: 26px;
        border-radius: 4px;
        color: #38434C;
        border: none;
        ::placeholder {
          padding: 4px 12px;
          color: #AFB7BE;
        }
        ::-moz-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
        :-ms-input-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
        ::-ms-input-placeholder {
          color: #AFB7BE;
          padding: 4px 12px;
        }
      }
      .react-select__control:hover {
        background: #F6F8FD;
      }
      .react-select__control:focus {
        border: 1px solid #0052cc!important;
      }
      .react-select__indicators {
        display: none;
      }
      .css-1jqq78o-placeholder {
        cursor: pointer;
      }
      .react-select__control {
        outline: none;
        border: none;
        cursor: pointer;
        margin: -4px -12px;
        min-height: 36px!important;
        height: 36px;
      }
      .react-select__control--menu-is-open {
        border: 1px solid #0052cc!important;
      }
      .react-select__control--is-focused {
        outline: none;
      }
      .react-select__menu {
        z-index: 3;
        white-space: nowrap;
        width: auto;
      }
      .react-select__control:focus {
        border: none;
        outline: none;
      }
      .react-select__menu {
        .react-select__option {
          height: 36px;
        }
      }
      .react-select__value-container {
        margin-right: 0;
        padding: 4px 12px;
        margin-top: -4px;
        display: flex;
        align-items: center;
        &:focus {
          border: 1px solid #0052cc;
        }
      }
      .profile-field-multi-dropdown {
        color: #38434C;
        font-size: 16px;
        min-height: 24px;
        padding: 8px 0;
        font-family: $baseFont;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        input, select, textarea{
          cursor: pointer;
          box-shadow: none;
          min-height: 36px;
        }
        .profile-field-title {
          min-width: 212px;
        }
        .css-13cymwt-control {
          box-sizing: border-box;
          outline: 0!important;
        }
        .react-select__multi-value__label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 300px;
        }
        .react-select__indicators {
          display: none;
        }
        .css-1jqq78o-placeholder {
          cursor: pointer;
        }
        .react-select__menu {
          z-index: 3;
          white-space: nowrap;
          width: auto;
        }
      }
      .profile-field-group {
        .profile-field {
          .profile-field-value {
            display: flex;
            align-items: center;
            height: 30px;
            .css-1dimb5e-singleValue {
              color: #38434C;
            }
            .profile-select {
              margin-left: -11px;
            }
            .react-select__control {
              padding: 4px 12px;
            }
          }
        }
      }
    }
  }
}

.job-info .info .info-body .profile-field .job-info-select .react-select__control--is-disabled {
  background-color: white;
  align-content: center;
  .react-select__single-value--is-disabled {
    color: #38434C;
  }
}
.default-pointer {
  background: none!important;
  border: 1px solid #fff!important;
  cursor: default!important;
  color: #757575!important;
  .css-1p3m7a8-multiValue {
    padding-right: 6px;
  }
  .css-16xfy0z-control {
    .css-v7duua {
      display: none;
    }
  }
  .css-1dyz3mf {
    padding: 0!important;
  }
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  &:hover {
    background: none!important;
    border: 1px solid #fff!important;
  }
}
.cursor-pointer {
  cursor: pointer;
  border: 1px solid #fff;
  &:hover {
    background: #F6F8FD;
    border: 1px solid #fff;
  }
  &:focus {
    border: 1px solid #0052cc;
  }
}
.disabled-pointer {
  cursor: url('../../../../../../assets/images/input/disabled-cursor.svg'), auto !important;
  .css-v7duua {
    display: none!important;
  }
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  .css-1dyz3mf {
    padding: 0!important;
  }
  &:hover {
    background: none!important;
    border: 1px solid #fff!important;
  }
}
@import "./src/utils/variables/base";

.meme-gen-widget {
  width: 404px;
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);
  border-radius: 12px;
  padding: 20px;
  margin-left: auto;
  margin-top: 25px;
  .meme-gen-header {
    border-bottom: 1px solid #e6eefa;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 0 10px;
    a {
      text-decoration: none;
      h2 {
        font-weight: 500;
        font-size: 20px;
        font-family: $baseFont;
        color: $blackDark;
        margin-left: 18px;
      }
    }
  }
  .widget-body {
    overflow-y: auto;
    overflow-x: hidden;
    width: 376px;
    padding: 0 15px 0 5px;
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
    }
    .meme-link-block {
      overflow: hidden;
      position: relative;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        position: absolute;
        z-index: 2;
        height: 300px;
      }
      .meme-bg-block {
        background-position: 50%;
        background-size: cover;
        filter: blur(5px);
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    p {
      color: #38434c;
      font-family: $baseFont;
      font-size: 16px;
      font-weight: 500;
      line-height: 35px;
      text-decoration: none;
    }
    .top-title {
      margin-top: 20px;
    }
  }
}
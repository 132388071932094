@import "./src/utils/variables/base";

.search-wrapper {
  position: relative;
  width: 314px;
  min-height: 36px;
  height: auto;
  background: $blueLighter2;
  border-radius: 20px;
  display: block;
  align-items: center;
  flex-direction: column;

  .search-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    padding: 8px 44px 8px 12px;
    cursor: pointer;

    input {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #6A7885;
      border: none;
      outline: none;
      padding-left: 8px;
      background: transparent;
      width: 100%;
      cursor: pointer;
    }

    .search-icon {
      color: #6A7885;
      width: 20px;
      height: 20px;
      position: relative;
      top: -2px;
    }

    .close-icon {
      color: #6A7885;
      width: 20px;
      height: 20px;
      position: relative;
      top: 1px;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .results {
    box-sizing: border-box;
    width: 100%;
    max-height: 460px;
    background: #FFFFFF;
    border-radius: 0 0 10px 10px;
    padding-left: 8px;
    padding-bottom: 12px;
    box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.13), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.11);
    overflow-y: auto;
    overflow-x: hidden;

    .results-title {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #6A7885;
      margin: 8px 0 0 12px;
    }

    .highlighted {
      background: $blueLightBlue !important;
    }

    .result-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      height: 54px;
      width: 430px;
      background: #FFFFFF;
      border-radius: 4px;

      &:hover {
        background: $blueLightBlue;
      }

      .avatar-container {
        margin-right: 8px;
        .avatar {
          background-color: #9AA4BE;
          height: 32px;
          width: 32px;
          border-radius: 25px;
          border: 1px solid #E6ECF1;
          overflow: hidden;
          margin-right: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .without-avatar {
          background-color: #9AA4BE;
          height: 32px;
          width: 32px;
          border-radius: 25px;
          border: 1px solid #E6ECF1;
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: #FFF;
            font-family: $baseFont;
            line-height: 32px;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      .user-data-container {
        display: flex;
        flex-direction: column;
        width: 50%;

        .nickname-request-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          .nickname {
            font-family: $baseFont;
            font-style: normal;
            font-weight: 550;
            font-size: 16px;
            line-height: 140%;
            color: $blackBaseColor;
          }

          .request-icon {
            display: flex;
            align-items: center;
            margin-left: 12px;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        .user-names {
          font-family: $baseFont;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $blackDark;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .company-data-container {
        margin-left: auto;
        text-align: right;
        color: $blackBaseColor;
        font-family: $baseFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        width: 40%;
      }
    }

    .no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      color: $blackBaseColor;
      height: 52px;
      padding-top: 12px;
    }
  }
  .search-settings {
    display: none;
    width: 100%;
    padding: 8px 0 8px 20px;
    border-top: 1px solid $blueLight;
    border-bottom: 1px solid $blueLight;
    background: $greyWhite;

    .select-chips {
      margin-right: 8px;
    }

    .clear-filters {
      margin-left: 4px;
      margin-top: 3.5px;

      color: $blackBaseColor;
      font-family: $baseFont;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    // react-multi-select-component
    .rmsc {
      font-family: $baseFont;
      font-size: 14px;
      .dropdown-container {
        border-radius: 30px;
        min-width: 64px;
        max-width: 157.5px;
        border: 1px solid $greyGrey;
        background: #FFFFFF;
      }
      .dropdown-heading {
        height: 25px;
        .dropdown-heading-value {
          margin-right: 4px;
          span {
            color: $blackDark;
          }
        }
      }
      .dropdown-content {
        border-radius: 4px;
        background: #FFFFFF;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 2px rgba(0, 0, 0, 0.04), 0 16px 24px -3px rgba(0, 0, 0, 0.06);
        padding: 4px;
        margin-top: 2px;
        width: 211px;
        .search {
          border-radius: 4px;
          border: 1px solid rgba(0, 115, 214, 0.30);
          background: $greyWhite !important;
          margin-bottom: 4px;
          input {
            padding: 6px 8px 6px 12px;
            height: 32px;
            background: transparent;
          }
        }
        .search:hover {
          box-shadow: 0 0 3px 0 rgba(0, 82, 204, 0.30);
        }
        .panel-content {
          box-shadow: none;
        }
        label {
          font-weight: 400;
          color: $blackDark;
        }
        .select-item {
          padding: 4px 0 4px 8px;
          .item-renderer {
            align-items: center;
          }
        }
        .options {
          max-height: 187px;
          width: 99%;

          // Mozilla-Firefox specific option
          scrollbar-color: #E6EEFA transparent;
        }

      }

      .select-item.selected {
        background: transparent;
      }
    }
  }
}

.search-wrapper.focused {
  background: white;
  width: 446px;
  min-height: 133px;
  height: auto;
  border: 1px solid #E6EEFA;
  box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.13), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.11);

  .search-input {
    padding: 11px 20px 11px 20px;
    cursor: default;

    input {
      height: 24px;
      padding-right: 14px;
      cursor: default;
      &::placeholder {
        color: transparent;
      }
    }

    .search-icon {
      top: 1px;
    }
  }

  .search-settings {
    display: flex;
    flex-direction: row;
  }
}

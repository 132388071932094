@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";
@import "./src/utils/fonts/roboto";

.button {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 6px 12px;
  align-items: center;

  .button-icon {
    margin-right: 4px;
    height: 20px;
  }
}

.big-button {
  width: 100%;
  height: 44px;
  font-size: 18px;
}

.medium-button {
  height: 32px;
  font-size: 14px;
}

.accent {
  background-color: $brandForegroundColor;
  font-family: $baseFont;
  font-weight: 500;
  color: #FCFDFE;
  box-shadow: $shadow2;
  transition: box-shadow .1s ease-in-out;
  &:hover {
    box-shadow: $shadow4;
  }
  .material-icons {
    color: #FCFDFE;
    margin: 0 5px 0 0;
  }
}

.light-blue {
  background-color: #E6EEFA;
  font-family: $baseFont;
  font-weight: 500;
  color: $brandForegroundColor;
  box-shadow: $shadow2;
  transition: box-shadow .1s ease-in-out;
  border: 1px solid #0052CC;
  &:hover {
    box-shadow: $shadow4;
  }
  .material-icons {
    color: $brandForegroundColor;
    margin: 0 5px 0 0;
    padding-left: 3px;
  }
}

.white {
  background-color: #FFFFFF;
  font-family: $baseFont;
  font-weight: 500;
  color: $brandForegroundColor;
  border: 1px solid $brandForegroundColor;
  .material-icons {
    color: $brandForegroundColor;
    margin: 0 5px 0 0;
  }
}

.grey {
  display: table-row;
  background-color: $lightGreyBase;
  font-family: Roboto,sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #38434c;
  border-radius: 14px;
  margin: 5px;
  cursor: default;
}

.disabled {
  background-color: $lightGreyBase;
  font-family: $baseFont;
  font-weight: 500;
  color: #FCFDFE;
  box-shadow: $shadow2;
  transition: box-shadow .1s ease-in-out;
  cursor: default;
  &:hover {
    box-shadow: $shadow4;
  }
  .material-icons {
    color: #FCFDFE;
    margin: 0 5px 0 0;
  }
}

.ghost {
  background-color: transparent;
  font-family: $baseFont;
  font-weight: 500;
  color: $brandForegroundColor;
  .material-icons {
    color: $brandForegroundColor;
    margin: 0 5px 0 0;
  }
}

.delete {
  font-family: $baseFont;
  font-weight: 500;
  background-color: #FF5961;
  color: #FFFFFF;
}

.add-item {
  margin-left: -9px!important;
}
.profile-image-loader {
  display: flex;
  flex-direction: row;
  .row-element {
    display: flex;
    flex-direction: row;
  }
  .column-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .cropper-view-box {
    border-radius: 50%;
  }
}
.delete-btn {
  position: absolute;
  bottom: 10px;
  margin-right: 75%;
}
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.sub-content-block {
  .greetings-table {
    .absolute {
      align-items: flex-start;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
      display: flex;
      flex-direction: column;
      margin-left: 0;
      width: 132px;
      padding: 4px;
      position: absolute;
      font-weight: 400;
      font-size: 14px;
      color: #38434C;
      margin-top: 8px;
      label {
        display: flex;
        align-items: center;
      }
      input {
        height: 15px;
        width: 15px;
        margin: 8px 10px 9px 5px;
      }
    }
    .vert-menu-btn {
      background: none;
    }
    width: 100%;
    border-collapse: collapse;
    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      text-align: left;
      height: 56px;
      color: #6A7885;
      padding: 16px 24px;
      background: #F6F8FD;
      white-space: nowrap;
      &:first-child {
        width: 600px;
      }
      &:last-child {
        width: 175px;
      }
      a {
        padding: 0 10px;
      }
      .material-icons {
        padding: 0 5px;
        cursor: pointer;
      }
    }
    td {
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
      border-radius: 2px;
      background: #FFF;
      padding: 16px 24px;
      border: none;
      color: $blackBaseColor;
      .material-icons {
        padding: 0 5px;
        font-size: 20px;
        cursor: pointer;
      }
      &:first-child {
        word-wrap: break-word;
        max-width: 450px;
      }
      &:last-child {
        white-space: nowrap;
      }
    }
    tr {
      border-bottom: 3px solid #f1f1f1;
      border-top: 3px solid #f9fafd;
    }
  }
}
@import "./src/utils/variables/base";

@media (max-width: 1360px) {
  .newbies-widget {
    padding: 20px 0 10px 40px;
    .newbies-absence-text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1361px) {
  .newbies-widget {
    padding: 20px 0 10px 60px;
    .newbies-absence-text {
      font-size: 18px;
    }
  }
}

.newbies-full-height {
  height: 298px!important;
  padding-bottom: 20px!important;
}

.pb-20 {
  padding-bottom: 20px!important;
}

.newbies-widget-main {
  background: linear-gradient(100.6deg,#fff .61%,#eff5ff 54.71%,#f4f6fa);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  margin-top: 25px;
  border-radius: 10px;
  padding: 10px;
  -webkit-backdrop-filter: blur(17.1695px);
  backdrop-filter: blur(17.1695px);
  .newbies-widget {
    height: max-content;
    margin-right: -10px;
    backdrop-filter: blur(17.1695px);
    background: linear-gradient(100.6deg,#fff .61%,#eff5ff 54.71%,#f4f6fa);
    .newbies-block-title {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #152029;
      margin-bottom: 16px;
    }

    .newbies-block-description {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #003563;
    }

    .newbies-body {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      max-height: 345px;
    }

    .newbies-absence-text {
      display: flex;
      flex-direction: column;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      align-items: center;
      text-align: center;
      color: #003563;
    }

    .newbies-container {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .newbies-container::before {
      background: linear-gradient(90deg, rgba(241, 245, 252, 0.00) 2.7%, rgba(242, 245, 251, 0.91) 100%);
      bottom: 0;
      content: "";
      pointer-events: none;
      position: absolute;
      right: 0;
      top: -10px;
      width: 82px;
      z-index: 5;
      border-radius: 10px;
    }

    .newbies-container::-webkit-scrollbar {
      height: 8px;
    }

    .newbie-card-container {
      flex: 0 0 auto;
    }

    .newbie-card {
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      width: 170px;
      height: 190px;
      margin: 0 12px 10px 0;
      text-align: center;
      border-radius: 10px;
      background: lightgray 50% / cover no-repeat;
      position: relative;
      flex-shrink: 0;
      overflow: hidden;
      cursor: pointer;
    }

    .newbie-card::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--dark-gradient, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 1, 2, 0.37) 100%));
      z-index: 1;
      border-radius: inherit;
    }

    .newbie-card.no-avatar::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.33) 100%);
    }

    .newbie-card-content {
      background-color: transparent;
      color: white;
      width: 100%;
      padding: 5px 12px;
      text-align: left;
      position: relative;
      z-index: 2;
    }

    .extended-newbie-card {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 441px;
      height: 190px;
      margin: 0 12px 10px 0;
      border-radius: 11px 10px 10px 11px;
      background: #003563;
      position: relative;
      flex-shrink: 0;

      .newbie-card {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-size: 120%;
      }

      .close-button {
        font-family: $baseFont;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.6000000238418579;
      }

      .newbie-details {
        color: white;
        margin-left: 20px;
      }

      .newbie-card.small {
        width: 185px;
        height: 190px;
        border: none;
        margin: 0;
        background-image: none;
      }

      .newbie-details {
        font-family: $baseFont;
        display: flex;
        flex-direction: column;
        height: 155px;
        justify-content: space-between;
        margin: 16px 0 0 20px;
        .newbie-extended-card-names {
          align-items: flex-start;
          color: #fff;
          display: inline-flex;
          flex-direction: column;
          flex-shrink: 0;
          font-size: 18px;
          font-weight: 500;
          justify-content: flex-start;
          margin-top: 20px;
          width: 220px;
        }
        .newbie-extended-card-job-title {
          margin-top: 8px;
          color: var(--blue-light-blue, #F3F8FC);
          font-size: 16px;
          line-height: 140%;
        }
        .newbie-extended-card-location {
          align-items: flex-start;
          color: var(--blue-light,#e6eefa);
          display: flex;
          font-size: 16px;
          line-height: 150%;
          margin-left: -5px;
          width: 220px;
          word-wrap: break-word;
        }
      }
    }
    .company-name {
      color: var(--white, #FFF);
      text-align: center;
      font-family: $baseFont;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: inline-block;
      padding: 0 4px;
      align-items: center;
      gap: 10px;
      border-radius: 2px;
      border: 1px solid #434343;
      background: rgba(21, 32, 41, 0.50);
      backdrop-filter: blur(9px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    .nickname {
      color: #FFF;
      font-family: $baseFont;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: block;
      margin-bottom: 5px;
    }
    .employee-card-link {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }
}
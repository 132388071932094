@import './src/utils/variables/base';
@import './src/utils/variables/shadow';

.sub-content-block {
  .push-notifications-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 24px;
    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      text-align: left;
      height: 56px;
      color: #6A7885;
      padding: 16px 24px;
      background: #F6F8FD;
      white-space: nowrap;
      &:last-child {
        width: 155px;
      }
      a {
        padding: 0 10px;
      }
    }
    td {
      font-size: 14px;
      font-weight: 400;
      font-family: $baseFont;
      border-radius: 2px;
      background: #FFF;
      padding: 16px 24px;
      border: none;
      color: $blackBaseColor;
      min-width: 155px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 780px;
      .material-icons {
        padding: 0 5px;
        font-size: 20px;
        cursor: pointer;
      }
      &:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    tr {
      border-bottom: 3px solid #f1f1f1;
      border-top: 3px solid #f9fafd;
    }
  }
}
.all-categories{
  margin-left: 65px;
}
.all-categories-title{
  font-family: Roboto,sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #38434c;
  margin-left: 5px;
}
@import "./src/utils/variables/base";

.links-widget {
  width: 404px;
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);
  border-radius: 12px;
  padding: 20px;
  margin-left: auto;

  .widget-body {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 350px;
    width: 376px;
  }

  .header {
    border-bottom: 1px solid #E6EEFA;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    font-family: $baseFont;
    color: $blackDark;
    margin-left: 18px;
  }
  li {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 18px;
    a {
      color: $blackBaseColor;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      font-family: $baseFont;
      text-decoration: none;
      &:hover {
        color: $brandForegroundColor;
      }
    }
    &::marker {
      color: #C2CAD1;
    }
  }
}
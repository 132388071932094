@import "./src/utils/variables/base";

.absent-widget::after {
  background: linear-gradient(180deg,transparent,#fff);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: calc(100% - 40px);
}
.absent-widget {
  padding: 20px 10px 0 23px;
  @media (min-width: 1361px) {
    padding: 20px 10px 0 43px;
  }
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);
  min-height: 195px;
  border-radius: 12px;
  margin-top: 25px;

  max-height: 400px;
  overflow-y: hidden;

  .widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btns-block {
      margin-right: 14px;
      margin-top: 5px;
    }
  }
  .absent-block-title {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #152029;
    margin-left: 17px;
  }

  .absent-block-description {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #003563;
    margin-left: 17px;
  }

  .absent-body {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 55px);
    justify-content: flex-start;
    margin-left: 17px;
    max-height: 312px;
    overflow-y: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    .react-tooltip {
      background: #fff!important;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 2px rgba(0, 0, 0, 0.04), 0 16px 24px -3px rgba(0, 0, 0, 0.06);
      color: #38434c!important;
      font-family: Roboto,sans-serif;
      margin-top: -5px;
      max-width: 300px;
      width: max-content;
      text-align: left;
      margin-left: 0!important;
      opacity: 1!important;
      padding: 8px!important;
      z-index: 999;
      position: absolute;
      height: max-content;
      span {
        padding: 0;
        height: max-content;
      }
      .additional-paid-time-off {
        padding: 5px;
        margin-top: -7px;
      }
      .popup-main-text {
        font-size: 16px;
        font-weight: 500;
        color: #003563;
        margin-top: 6px;
      }
      .popup-main-holiday-text {
        color: #003563;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-left: 5px;
      }
      .company, .holiday-text {
        background: #e6eefa;
        border-radius: 4px;
        height: 20px;
        margin-bottom: 7px;
        padding: 0 6px;
        width: max-content;
      }
      .employee-block {
        display: flex;
        flex-direction: row;
        align-content: center;
        height: 36px;
      }
      .type-block {
        margin-top: 7px;
        align-items: center;
        align-content: center;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        height: 24px;
        padding: 0 5px;
        margin-bottom: 7px;
        width: max-content;
        box-sizing: border-box; /* new line */

        .vacation-block {
          width: 20px;
          height: 20px;
          box-sizing: border-box; /* new line */
        }

        span {
          margin-top: 3px;
          margin-left: 5px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
      .not-confirmed {
        background: #f2f2f2;
        color: #38434c;
        font-size: 14px;
        height: 20px;
        padding: 1px 5px;
        border-radius: 4px;
      }
      .replacing {
        color: #38434c;
        font-size: 14px;
        height: 20px;
        padding: 1px 5px;
        border-radius: 4px;
      }
      .working-hours {
        font-weight: 500;
        font-size: 16px;
      }
      .schedule-block {
        display: flex;
        white-space: nowrap;
        font-size: 14px;
        margin-top: 5px;
        span {
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
        };
        i {
          font-size: 22px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }
      .tool-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2px 0 7px;
        i {
          display: flex;
          justify-content: end;
          width: 20px;
          font-size: 18px;
          color: #6A7885;
          margin-right: 5px;
          span {
            font-weight: 600;
          }
        }
      }
      .without-avatar {
        background-color: #9aa4be;
        border: 1px solid #e6ecf1;
        border-radius: 25px;
        display: inline-grid;
        margin-right: 8px;
        width: 32px;
        height: 32px;
        p {
          color: #fff;
          font-family: Roboto,sans-serif;
          line-height: 31px;
          text-align: center;
          width: 30px;
          height: 30px;
        }
      }
      .with-avatar {
        margin-right: 8px;
        display: inline-grid;
        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
        }
      }
    }
  }

  .no-absence-text {
    display: flex;
    flex-direction: column;
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    align-items: center;
    text-align: center;
    color: #003563;
    margin: auto;
  }

  .employee-card {
    position: relative;
    margin-right: 1.1em;
    margin-bottom: 12px;
    .employee-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 66px;
        width: 66px;
        border-radius: 50%;
      }
    }
    .empty-image {
      background: #9aa4be;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 32px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        font-family: $baseFont;
      }
    }
    .request-icon {
      position: absolute;
      right: -1px;
      bottom: -5px;
    }
  }
}
@import "src/utils/variables/base";

.accrual-days-modal {
  .modal-body {
    padding: 20px 36px !important;
  }

  .modal-content {
    border-radius: 10px;
  }

  .accrued-days-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #E6EEFA;
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
    color: var(--black-dark-blue, #003563);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: $baseFont;

    .material-icons {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .balance-field {
    margin-top: 20px;
    margin-bottom: -10px;
  }

  .modify-balance-container {
    label {
      font-weight: 500;
      line-height: 140%;
      margin-top: 8px;
    }

    .modify-balance {
      width: 100% !important;
    }
  }

  .amount-field {
    display: flex;
    flex-direction: row;
    font-family: $baseFont;
    font-weight: 500;
    line-height: 140%;
    color: $blackBaseColor;
    margin-bottom: 20px;

    .amount {
      margin-top: 8px;
      margin-right: 57px;
    }

    .days {
      margin-top: 8px;
    }

    input {
      height: 36px !important;
    }
    .input .error {
      width: 150px;
    }
  }

  .comment-field {
    margin-bottom: -10px;
  }
}

.profile-wrapper {
  .modal-header {
    border-radius: 4px 4px 0 0;
  }

  .modal-footer {
    border-radius: 0 0 4px 4px;
  }
}

.balance-field p,
.comment-field p {
  text-align: left;
}
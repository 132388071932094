@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.request-modal {
  .modal-content {
    min-width: 497px !important;
    width: auto !important;
    max-width: min-content !important;
    .modal-footer {
      padding: 12px 36px!important;
    }
  }

  .modal-header {
    background: #ffffff;
    h2 {
      line-height: 26px;
      margin-top: 0;
    }
  }

  .react-tooltip span p {
    font-size: 14px;
  }

  #app-title {
    margin: 0 0 0 3px;
  }
  .modal-body {
    padding: 0;

    .bold {
      font-weight: 500;
    }
    .calendar-link, .calendar-link:visited {
      font-weight: 500;
      color: #38434C;
    }
    .available-block {
      display: flex;
      justify-content: space-between;

      span:last-child {
        color: #6A7885;
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #38434C;
      font-family: $baseFont;
      padding-top: 10px;

      span {
        font-weight: 400;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    .date-block {
      display: flex;
      flex-direction: row;

      .react-datepicker__navigation {
        top: 20px !important;
      }

      .react-datepicker__day--disabled {
        color: #ccc !important;
      }
    }

    .total-working-days {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      background: $blueLight;
      height: 39px;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 16px;

      color: $blackDark;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $baseFont;
      font-size: 16px;
      font-style: normal;
      line-height: normal;

      .total-working-days-label {
        font-weight: 400;
        margin-left: 16px;
      }

      .total-working-days-label-bold {
        font-weight: 500;
        margin-left: 4px;
      }
    }

    .horizontal-input label {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      min-width: auto;
      width: max-content;
      margin-right: 8px;
    }

    .css-b62m3t-container, .css-b62m3t-container {
      position: relative;
      box-sizing: border-box;
      min-width: 204px;
      width: max-content;
      margin-bottom: 10px;
    }

    .horizontal-input .input-container, .horizontal-input label {
      padding: 0;
    }

    .request-step {
      border-bottom: 1px solid #E6ECF1;
      transition: background-color 0.3s ease;
      padding: 16px 36px 0 36px;
      background-color: var(--background, #F9FAFD);
      textarea {
        max-height: 72px;
      }
      label {
        line-height: 36px;
      }
    }

    .request-step.selected {
      background-color: var(--blue-light-blue, #F3F8FC);
      border-left: 5px solid var(--blue-main, #0052CC);
      padding: 16px 36px 0 31px;
    }

    .custom-radios-block {
      justify-content: flex-start;
      width: 100%;
      display: flex;
      margin-top: 15px;
      max-width: 417px;
      flex-wrap: wrap;
      div {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 16px;
        span {
          margin-left: 8px;
          font-family: $baseFont;
        }
      }
      .choosen-type {
        background: #F3F8FC;
        border: 1px solid #0052CC;

      }
    }

    .custom-radios-block > div {
      margin-right: 28px;
      border: 1px solid #C2CAD1;
      background: #ffffff;
    }
    .custom-radios-block > div:nth-child(3n) {
      margin-right: 0;
    }
  }

  .request-modal-footer {
    background: #fcfdfe;
    box-shadow: inset 0 0.5px 0 0 #c2cad1;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding: 12px 36px;
    margin: 0 !important;
    .request-btn {
      font-family: $baseFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      height: 32px;
      width: max-content;
      padding: 0 12px;
      float: left;
      margin-right: 18px;
      align-items: center;
      display: flex;
    }

    .Approve {
      box-shadow: 0 0.15px 0.45px rgba(0,0,0,.11), 0 0.8px 1.8px rgba(0,0,0,.13);
      color: #0052cc;
      font-family: $baseFont;
      font-weight: 500;
      transition: box-shadow .1s ease-in-out;
      border: 1px solid #0052cc;
      &:hover {
        color: #0052cc;
        background: #E6EEFA;
        border: 1px solid #0052cc;
      }
    }

    .close {
      color: #6A7885;
      border: 1px solid #C2CAD1;
      margin-left: 10px;
    }

    .close-without-border {
      color: #6A7885;
      margin-right: 0 !important;
      &:hover {
        background: #C2CAD1;
      }
    }

    .Reject {
      color: #6A7885;
      border: 1px solid #C2CAD1;
      margin-left: 80px;
      &:hover {
        background: #C2CAD1;
      }
    }

    .Cancel {
      color: #6A7885;
      border: 1px solid #6A7885;
    }
  }
  .modal-footer {
    .ghost {
      color: #6A7885 !important;
    }
  }
}
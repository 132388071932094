@import "src/utils/variables/base";

.breadcrumbs-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
  margin: 16px 0 12px 40px;
  gap: 8px;
  color: var(--black-black-light, #6A7885);
  font-feature-settings: 'liga' off;
  font-family: $baseFont;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;

  .material-symbols-outlined {
    font-size: 16px;
  }

  .home {
    display: flex;
    flex-direction: row;
    padding: 4px;
    gap: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      color: #0052CC;
      background-color: #E6EEFA;
      border-radius: 4px;
    }
  }

  .breadcrumb-item {
    padding: 4px;
  }
}
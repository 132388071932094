@import "./src/utils/variables/base";

@media (max-width: 1360px) {
  .celebrations-widget {
    .celebrations-absence-text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1361px) {
  .celebrations-widget {
    .celebrations-absence-text {
      font-size: 18px;
    }
  }
}

.celebrations-widget {
  padding: 20px 10px 20px 23px;
  @media (min-width: 1361px) {
    padding: 20px 10px 20px 43px;
  }
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);

  border-radius: 12px;
  margin-top: 25px;

  .celebrations-block-title {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #152029;
    margin-left: 17px;
    margin-bottom: 5px;
  }

  .celebrations-block-description {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #003563;
    margin-left: 17px;
  }

  .celebrations-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-height: 355px;
    overflow-y: auto;
    padding-top: 5px;
  }

  .celebrations-absence-text {
    display: flex;
    flex-direction: column;
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    align-items: center;
    text-align: center;
    color: #003563;
    height: 100%;
  }

  .column {
    min-width: 378px;
    width: 100%;
    margin: 0 auto;
    padding: 0 5px;
  }

  .event-card {
    min-width: 378px;
    width: 100%;
    height: 64px;
    border-radius: 10px;
    display: flex;
    padding: 8px 26px 8px 10px;
    @media (min-width: 1361px) {
      padding: 8px 20px 12px 10px;
      margin-bottom: 8px;
    }
    align-items: center;
    position: relative;
  }

  .event-image-wrapper {
    position: relative;
    display: inline-block;
    .event-image-container {
      .gift-icon {
        bottom: 4px;
        right: 14px;
      }
      .pyramid-icon {
        bottom: 4px;
        right: 14px;
      }
    }
  }

  .event-image {
    width: 48px;
    height: 48px;
    @media (min-width: 1361px) {
      width: 66px;
      height: 66px;
    }
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
    @media (min-width: 1361px) {
      margin-right: 16px;
    }
    position: relative;
  }
  .empty-image {
    background: #9aa4be;
    width: 48px;
    height: 48px;
    @media (min-width: 1361px) {
      width: 66px;
      height: 66px;
    }
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
    @media (min-width: 1361px) {
      margin-right: 16px;
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #F1E6FF;
    p {
      font-size: 32px;
      text-align: center;
      color: #ffffff;
      width: 100%;
      font-family: $baseFont;
    }
    .gift-icon {
      bottom: -3px;
      right: -3px;
    }
    .pyramid-icon {
      bottom: -3px;
      right: -3px;
    }
  }
  .image-birthday {
    border: 3px solid #F1E6FF;
  }
  .image-anniversary {
    border: 3px solid #E6EEFA;
  }
  .gift-icon {
    display: grid;
    place-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    @media (min-width: 1361px) {
      width: 24px;
      height: 24px;
    }
    text-align: center;
    background: linear-gradient(139.53deg, #A9A7FF 1.52%, #945DED 97.72%);
    border-radius: 14px;
    box-sizing: border-box;
    img {
      width: 10px;
      height: 10px;
      @media (min-width: 1361px) {
        width: 16px;
        height: 16px;
      }
    }
  }
  .pyramid-icon {
    display: grid;
    place-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    @media (min-width: 1361px) {
      width: 24px;
      height: 24px;
    }
    text-align: center;
    background: linear-gradient(139.53deg, #91D4FA 1.52%, #0194FE 97.72%);
    border-radius: 14px;
    box-sizing: border-box;
    img {
        width: 12px;
        height: 12px;
        @media (min-width: 1361px) {
            width: 18px;
            height: 18px;
        }
    }
  }

  .event-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .event-info-first-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      @media (min-width: 1361px) {
        font-size: 18px;
      }
      line-height: 150%;
      color: #152029;
      margin-bottom: 3px;
    }

    .event-info-second-line {
      display: flex;
      flex-direction: row;
      justify-content: left;
      font-family: $baseFont;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      @media (min-width: 1361px) {
        font-size: 18px;
      }
      line-height: 150%;
      color: #152029;
      align-items: center;
    }

    .employee-name {
      font-weight: 500;
    }
  }

  .event-date{
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background: linear-gradient(139.53deg, #A9A7FF 1.52%, #945DED 97.72%);
    border-radius: 2px;
    padding: 0 4px;
    font-size: 16px;
    height: 18px;
    @media (min-width: 1361px) {
      font-size: 18px;
      height: 20px;
    }
  }

  .event-years {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background: linear-gradient(139.53deg, #91D4FA 1.52%, #0194FE 97.72%);
    border-radius: 2px;
    padding: 0 4px;
    height: 18px;
    margin-left: 4px;
    position: relative;
    font-size: 16px;
    @media (min-width: 1361px) {
      font-size: 18px;
      height: 20px
    }
  }
}
@import "./src/utils/variables/base";

.clock-widget {
  width: 404px;
  background: linear-gradient(100.6deg, #FFFFFF 0.61%, #EFF5FF 54.71%, #F4F6FA 100%);
  box-shadow: 0 76.2792px 116.538px rgba(0, 39, 140, 0.13);
  backdrop-filter: blur(17.1695px);
  border-radius: 12px;
  padding: 20px 20px 20px 16px;
  @media (min-width: 1520px) {
    padding: 20px;
  }
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 24px;

  .time-zone-container {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .time {
    display: block;
    font-family: $baseFont;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 52px;
    color: $blackBaseColor;
  }

  h4 {
    font-family: $baseFont;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $blackBaseColor;
  }

  .timezone-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    margin-left: 16px;

    .current-timezone {
      display: block;
      font-family: $baseFont;
      color: $blackDark;
      font-size: 14px;
      @media (min-width: 1520px) {
        font-size: 16px;
      }
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .timezone {
      display: block;
      font-family: $baseFont;
      color: $blackDark;
      font-size: 18px;
      @media (min-width: 1520px) {
        font-size: 20px;
      }
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
    }
  }

  .additional-timezones {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }

  .timezone-container {
    display: flex;
    padding: 4px 16px 4px 12px;
    @media (min-width: 1520px) {
        padding: 4px 8px 4px 6px;
    }
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 0.5px solid var(--blue-light, #E6EEFA);
    background: var(--white, #FFF);
    width: fit-content;
    min-width: 50px;
    max-width: 106px;
    gap: 3px;
  }

  .city {
    font-family: $baseFont;
    color: var(--black-dark-blue, #003563);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    @media (min-width: 1520px) {
      font-size: 16px;
    }
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .additional-time {
    font-family: $baseFont;
    color: var(--black-black-dark, #152029);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    @media (min-width: 1520px) {
      font-size: 16px;
    }
    line-height: normal;
  }

  .divider {
    width: 364px;
    height: 1px;
    background: #E6EEFA;
    margin-top: 6px;
    margin-bottom: 12px;
  }
}
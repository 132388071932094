@import "./src/utils/variables/base";

.calendar-widget {
  border: none !important;
  border-radius: 12px !important;
  color: #152029 !important;
  display: inline-block;
  font-family: $baseFont !important;
  position: relative;
  width: 404px !important;
  height: auto !important;
  padding-bottom: 10px !important;
  margin-bottom: 24px;
  font-size: 18px !important;
  background: linear-gradient(134deg, #FFF 0%, #EFF5FF 54.43%, #F4F6FA 100%) !important;
  box-shadow: 0 76.2792px 116.538px rgb(0 39 140 / 13%);

  .divider {
    width: 364px;
    height: 1px;
    background: #E6EEFA;
    margin: 6px 0 10px 20px;
  }

  .calendar-list-wrapper {
    position: relative;

    .calendar-list-container {
      max-height: 363px;
      overflow-y: auto;
      width: 396px;
      padding: 0 44px 16px 32px;
      z-index: 1;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: #E6EEFA;
      }

      &::-webkit-scrollbar-track {
        border-radius: 15px;
      }

      .calendar-list {
        color: var(--black-black-base, #38434C);
        font-family: $baseFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;

        .month-container {
          .month-header {
            padding: 5px;
            position: sticky;
            top: 0;
            background-color: #F1F6FE;
            width: 340px;
            margin-left: -5px;
            font-weight: 500;
          }

          .month-header.past-event {
            color: $greyBorder;
          }

          .month-body {
            padding-bottom: 16px;
          }

          p {
            padding-top: 8px;
          }

          p.past-event {
            color: $greyBorder;

            span {
              font-weight: 500;
              color: #82a2bd;
              margin-right: 5px;
            }

            span.day-off {
              font-weight: 500;
              color: #ffacad;
              margin-right: 5px;
            }
          }

          span {
            font-weight: 500;
            color: #003563;
            margin-right: 5px;
          }

          span.day-off {
            font-weight: 500;
            color: #FF5961;
            margin-right: 5px;
          }

        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 37px;
      border-radius: 0 0 12px 12px;
      background: linear-gradient(0deg, #FFF 2.70%, rgba(242, 245, 251, 0.00) 100%);
      pointer-events: none;
      z-index: 2;
    }
  }

  .react-calendar__navigation {
    display: flex;
    padding: 0 38px 12px;
  }

  .react-calendar__navigation__label {
    background: transparent !important;
    color: var(--black-black-dark, #152029);
    text-align: center;
    font-family: $baseFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: -5px !important;
    padding-top: 3px !important;
    pointer-events: none !important;
  }

  .react-calendar__navigation__prev-button {
    transform: rotate(-180deg);
  }

  .react-calendar__month-view__weekdays__weekday {
    line-height: 33px !important;
    text-align: center !important;
    color: #6a7885 !important;
    display: inline-block !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation__arrow {
    cursor: pointer;
    background: transparent;

    img {
      width: 15px;
      height: 15px;
      margin-top: 2px;
    }
  }

  .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__month-view {
    padding: 0 38px 8px 38px;
  }

  .react-calendar__month-view__days__day {
    background: transparent !important;
    width: 52px !important;
    height: 48px !important;
    padding: 12px !important;
    font-family: $baseFont !important;
    &:hover {
      .calendar-tooltip {
        opacity: 1;
      }
    }

    abbr {
      position: relative;
      background: transparent !important;
      cursor: pointer !important;
      color: $blackDark !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      align-items: center !important;
      justify-content: center !important;
      display: flex !important;
      padding: 0 2px !important;
      width: 100% !important;
      height: 100% !important;
      font-family: $baseFont !important;
    }
  }

  .react-calendar__tile--now {
    padding: 5px 6px !important;

    &:hover {
      abbr {
        background-color: #0044AA !important;
      }
    }

    abbr {
      background-color: #0044AA !important;
      color: #fff !important;
      border-radius: 25px !important;
      width: 40px !important;
      height: 40px !important;

      &:before {
        top: 27px !important;
      }
    }
  }

  .calendar-outside-month {
    abbr {
      color: #b0b0b0 !important;
    }
  }

  .calendar-not-workday {
    abbr {
      background-color: $red !important;
      border-radius: 4px !important;
      color: $redBaseColor !important;
    }
  }

  .calendar-state-holiday {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/orange.svg');
        height: 6px;
        left: 50%;
        margin-left: -3px;
        top: 20px;
        width: 6px;
      }
    }
  }

  .calendar-professional-holiday {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/green.svg');
        height: 6px;
        left: 50%;
        margin-left: -3px;
        top: 20px;
        width: 6px;
      }
    }
  }

  .calendar-state-holiday-and-professional-holiday {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/green-orange.svg');
        height: 6px;
        left: 50%;
        margin-left: -6px;
        top: 20px;
        width: 14px;
      }
    }
  }

  .calendar-state-holiday-and-corporate-event {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/orange-blue.svg');
        height: 6px;
        left: 50%;
        margin-left: -6px;
        top: 20px;
        width: 14px;
      }
    }
  }

  .calendar-professional-holiday-and-corporate-event {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/green-blue.svg');
        height: 6px;
        left: 50%;
        margin-left: -6px;
        top: 20px;
        width: 14px;
      }
    }
  }

  .calendar-corporate-event {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/blue.svg');
        height: 7px;
        left: 50%;
        margin-left: -2px;
        top: 20px;
        width: 6px;
      }
    }
  }

  .calendar-all-in {
    abbr {
      &:before {
        position: absolute;
        content: url('../../../../../assets/images/input/main-calendar/green-orange-blue.svg');
        height: 6px;
        left: 50%;
        margin-left: -11px;
        top: 20px;
        width: 22px;
      }
    }
  }

  .calendar-tooltip {
    position: absolute;
    opacity: 0;
    background: #FFF;
    padding: 12px 12px 12px 8px;
    min-width: 120px;
    margin-left: -100px;
    box-shadow: 0 16px 24px -3px rgba(0, 0, 0, 0.06), 0 2px 6px 2px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    z-index: 100;
    pointer-events: none;

    .tooltip-container {
      color: $blackDark;
      font-family: $baseFont;
      padding-left: 6px;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      .event-name {
        display: table;
        text-align: left;
        color: $blackDark;
        font-family: $baseFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .event-type-name {
        display: table;
        text-align: left;
        color: $blackDark;
        font-family: $baseFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
      }

      .paid-time-off-text {
        display: table;
        text-align: left;
        color: $blackDark;
        font-family: $baseFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 4px 8px;
        background: $greyLight;
        border-radius: 2px;
        margin-top: 5px;
      }

      p {
        display: table;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
      }

      span {
        display: table;
        font-size: 12px;
        font-weight: 400;
        padding: 4px 8px;
        background: $greyLight;
        border-radius: 2px;
        margin-top: 5px;
      }
    }

    .tooltip-state-holiday {
      border-left: 3px solid #FF9900;
    }

    .tooltip-professional-holiday {
      border-left: 3px solid #2DAE5D;
    }

    .tooltip-corporate-event {
      border-left: 3px solid #0052CC;
    }
  }
}

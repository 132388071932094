@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.calendar-main-content {
  display: flex;
  max-width: 1564px;
  width: 95%;
  margin: 60px auto 0;
  padding: 0 24px !important;
  min-height: auto!important;
  footer {
    margin-top: 50px;
    position: fixed;
    bottom: 0;
    margin-left: 58px;
  }
  .z-index {
    z-index: 2;
  }
  .breadcrumbs-and-header {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    background-color: $backgroundColor;
    z-index: 3;
    .breadcrumbs-block {
      margin: 16px 0 12px 0 ;
    }
    .header {
      color: var(--black-black-dark, #152029);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $baseFont;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .sub-content-block {
    width: 100%;
    margin: 97px auto 0 !important;
  }
}
.loading {
  margin-left: -40px;
  margin-top: 15px;
  text-align: center;
  width: 100%;
  height: 50px;
}
.clear-selected-button {
  display: none!important;
}
.disabled-filter {
  .dropdown-heading {
    background: #e7e7e7!important;
  }
}
@keyframes svg-spinner {
  0% { transform: rotate(5deg); }
  50% { transform: rotate(185deg); }
  100% { transform: rotate(365deg); }
}
.svg-spinner {
  margin: 0 2px 0 0;
  vertical-align: -2px;
}
.svg-spinner svg {
  animation: svg-spinner 1.3s linear infinite;
}
.svg-spinner .path {
  stroke-dasharray: 60;
  stroke-dashoffset: 30;
  transform-origin: center;
}
.add-request {
  margin-left: 24px!important;
  .sub-content-main {
    padding-top: 0!important;
    padding-right: 0 !important;
  }
  .content {
    padding-top: 38px!important;
    height: calc(100vh - 271px);
  }
  .add-button-block {
    position: absolute;
    right: 0;
    margin-top: -90px;
    z-index: 3;
  }
  .input .error {
    left: 3px !important;
  }
  .filter:first-child {
    width: 40px;
  }
  .date-range {
    width: 225px!important;
  }
  .filter {
    margin-left: 8px;
    width: 170px;
    .calendar-today {
      position: absolute;
      margin-left: -215px;
      font-size: 21px;
      pointer-events: none;
      margin-top: 4px;
      color: #aaaaaa;
    }
    .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected, .react-datepicker__month-text--in-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--selected, .react-datepicker__quarter-text--in-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--selected, .react-datepicker__year-text--in-range, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--selected {
      border-radius: 50%;
      background-color: #216ba5!important;
      color: #FFFFFF!important;
    }
    .react-datepicker__day--disabled {
      color: #ccc!important;
    }
    .react-datepicker__tab-loop {
      position: absolute;
      margin-left: -75px;
      margin-top: -25px;
    }
    .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
      top: 21px!important;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: #216ba5!important;
      border-radius: 50%;
      color: #FFFFFF!important;
    }
    .date-range-delete {
      position: absolute;
      margin-left: -50px;
      margin-top: 2px;
      color: #aaaaaa;
      cursor: pointer;
    }
    .react-datepicker__input-container input {
      font-size:14px;
      height: 28px!important;
    }
    .react-datepicker__close-icon:after {
      background-color: inherit;
      border-radius: 50%;
      color: #aaa;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      height: 16px;
      line-height: 1;
      padding: 0;
      text-align: center;
      vertical-align: middle;
      width: 16px;
      content: '';
    }

    .react-datepicker__close-icon:after svg {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: currentColor;
      stroke-width: 2px;
    }
    .horizontal-input .input-container input {
      height: 28px!important;
    }
    .input-container {
      padding: 0;
    }
    .multi-select-with-checkboxes {
      font-size: 14px;
      height: 28px;
      width: 170px;
      .dropdown-heading {
        background: #ffffff;
        border: 0.5px solid #c2cad1!important
      }
      .dropdown-content {
        position: absolute!important;
        background: #FFFFFF;
        height: auto!important;
        label {
          display: block !important;
          color: #152029;
          font-weight: 400;
        }
      }
    }
    label {
      display: none;
    }
  }
  .clear-filters {
    color: #6A7885;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    font-family: $baseFont;
    cursor: pointer;
  }
}

.request-title-block {
    padding: 16px 48px !important;
    box-shadow: none !important;
    background: $backgroundColor;
    display: flex;
}

.calendar-date-filter {
  margin-left: 150px;
  z-index: 3;
  position: fixed;
  display: flex;
  margin-top: -80px;
  i {
    font-size: 27px;
    cursor: pointer;
  }
  .react-datepicker-popper {
    margin-left: -60px;
    padding-top: 0;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    width: 280px!important;
  }
  .react-datepicker__navigation--next, .react-datepicker__navigation--previous {
    top: 22px!important;
  }
  .react-datepicker__input-container {
    input {
      color: #38434C;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border: none !important;
      background: none !important;
      padding: 0 !important;
      height: 28px !important;
      cursor: pointer;
    }
    .react-datepicker-ignore-onclickoutside {
      color: #38434C;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border: none !important;
      background: none !important;
      width: min-content !important;
      padding: 0 !important;
      height: 28px !important;
      cursor: pointer;
    }
  }
}

.sub-content-main {
  .vert-menu-btn {
    background: none;
    .material-icons {
      cursor: pointer!important;
    }
  }
  .check-success {
    color: #2DAE5D;
  }
  .center {
    text-align: center!important;
  }
  .zero-padding {
    padding: 0!important;
  }
  .count-margin-fix {
    margin-left: -20px;
  }
  .modal-content {
      .what-to-delete {
        display: contents!important;
      }
      p {
        text-align: left;
      }
  }

  .absolute {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 5px;
    margin-top: 4px;
    .material-icons {
      margin: 5px 0;
      font-size: 20px!important;
      padding: 0 2px!important;
      cursor: pointer!important;
    }
  }
  .dropdown-content {
    height: auto!important;
    min-width: 100%;
    width: max-content!important;
    ul {
    padding-bottom: 6px;
    label {
        padding: 4px 12px;
        .item-renderer {
          display: flex;
          align-items: center;
          input {
            margin: -1 8px 0 0;
            width: 14px;
            height: 14px;
          }
        }
        &:first-child {
          border-bottom: 1px solid #DFE1E6;
          margin-bottom: 4px;
          margin-top: 3px;
        }
      }
      li {
        padding: 0;
        label {
          padding: 4px 12px;
          &:first-child {
            border-bottom: none;
            margin-bottom: auto;
          }
        }
      }
    }
  }
  .calendar-menu-block {
    background: $backgroundColor;
    position: fixed;
    z-index: 2;
    padding: 10px 0;
    height: 48px;
    margin-top: -11px;
  }
  .calendar-menu {
    background: $backgroundColor;
    display: flex;
    list-style-type: none;
    margin-top: -10px;
    padding-top: 0;
    position: fixed;
    width: auto;
    z-index: 1;
    li {
      font-family: $baseFont;
      color: #38434C;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      padding: 11px 0;
      margin-right: 20px;
    }
    .active {
      color: #0052CC;
      border-bottom: 3px solid #0052CC;
    }
  }
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.add-employee {
  .add-button-block {
    position: absolute;
    right: 50px;
    margin-top: -90px;
    z-index: 2;
  }
  .input .error {
    left: 20px!important;
  }
}

.sub-content-main {
  .pagination {
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 24px -3px rgba(0, 0, 0, 0.06), 0px 2px 6px 2px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 0 0 4px 4px;
    background: #fff;
    padding: 10px 20px;
    font-family: $baseFont;
    margin-bottom: 150px;
    min-height: 48px;
    .pagination-pages {
      flex-wrap: wrap;
      width: initial;
    }
    .disabled-icon {
      color: #b6b6b6;
      cursor: default!important;
    }
    .rows-per-page {
      display: flex;
      align-items: center;
      min-width: max-content;
      span {
        font-size: 12px;
        color: #6A7885;
      }
      .rows-per-page-filter {
        .react-select__control {
          border: none !important;
          background: #FFFFFF !important;
          font-family: $baseFont;
          cursor: pointer;
        }
        .css-8mmkcg {
          display: inline-block;
          fill: currentcolor;
          line-height: 1;
          stroke: currentcolor;
          stroke-width: 0;
          width: 14px;
          margin-left: -5px;
          margin-top: -1px;
        }
        .css-1dimb5e-singleValue {
          font-weight: 500;
          font-size: 14px;
        }
        .react-select__value-container {
          padding: 0 !important;
          margin-left: -10px;
        }

        label {
          display: none !important;
        }

        .react-select__menu {
          z-index: 3;
          white-space: nowrap;
          width: auto;
        }
      }
    }
    .pagination-pages {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      width: 100%;
      justify-content: center;
      .pagination-page {
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        padding: 8px 18px;
      }
      span a {
        color: #38434c;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        font-family: $baseFont;
      }
      .pagination-page.active {
        background: #E6EEFA;
        border-radius: 4px;
      }
      .pagination-prev,
      .pagination-next {
        display: inline-block;
        padding: 5px 10px;
        background-color: #eee;
        border-radius: 5px;
        cursor: pointer;
      }
      .pagination-prev.disabled,
      .pagination-next.disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
      .pagination-select {
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .pagination-info {
      display: flex;
      align-items: center;
      min-width: max-content;
      .right {
        width: 60px;
        text-align: center;
        margin-top: -10px;
        cursor: pointer;
      }
      .left {
        width: 40px;
        margin-top: -10px;
        cursor: pointer;
      }
      .pagination-total {
        margin-right: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #38434C;
        span {
          text-decoration: none;
          font-size: 12px;
          color: #6A7885;
          font-weight: 400;
        }
      }
    }
  }
  .content {
    padding-top: 44px;
  }
  .vert-menu-btn {
    background: none;
    .material-icons {
      cursor: pointer!important;
    }
  }
  .check-success {
    color: #2DAE5D;
  }
  .center {
    text-align: center!important;
  }
  .zero-padding {
    padding: 0!important;
  }
  .count-margin-fix {
    margin-left: -20px;
  }
  .modal-content {
      .what-to-delete {
        display: contents!important;
      }
      p {
        text-align: left;
      }
  }

  .absolute {
    margin-left: 75px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 2.4px 7.4px rgb(0 0 0 / 18%), 0 12.8px 28.8px rgb(0 0 0 / 22%);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 5px;
    margin-top: 0;
    .material-icons {
      margin: 5px 0;
      font-size: 20px!important;
      padding: 0 2px!important;
      cursor: pointer!important;
    }
  }
  .employee-menu {
    background-color: $backgroundColor;
    display: flex;
    list-style-type: none;
    margin-left: -10px;
    margin-top: -7px;
    position: fixed;
    width: calc(100% - 395px);
    z-index: 1;
    padding-top: 10px;
    li {
      font-family: $baseFont;
      color: #38434C;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      padding: 11px 0;
      margin: 0 10px;
    }
    .active {
      color: #0052CC;
      border-bottom: 3px solid #0052CC;
    }
  }
}
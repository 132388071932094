@import 'src/utils/variables/base';
@import 'src/utils/variables/shadow';

.emergency-contact {
  .help-text {
    display: flex;
    align-items: center;
    color: #003563;
    padding: 0 18px;
    font-family: $baseFont;
    background: #E6EEFA;
    border-radius: 4px;
    height: 36px;
    margin: 0 0 5px -44px;
    width: max-content;
    font-size: 14px;
    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0 0.15px 0.45px rgba(0, 0, 0, 0.11), 0 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    margin: 16px 0;
    .info-header {
      border-bottom: 0.5px solid #dfe1e6;
      height: 60px;
      width: 100%;
      padding: 7px 0 0 80px;
      display: flex;
      align-items: center;
      .emergency-icon {
        margin-right: 12px;
      }
      h3 {
        font-family: $baseFont;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #152029;
      }
    }
    .info-body {
      width: 100%;
      padding: 16px 0 10px 80px;
      #app-title {
        margin-left: 5px;
      }
      .profile-field-value {
        display: flex;
        width: auto;
        position: relative;
        .input .input-container {
          margin-top: 0;
          position: relative;
          width: auto!important;
          height: 36px;
          textarea {
            height: 36px;
            overflow: hidden;
            background: #ffffff;
            cursor: pointer;
            box-sizing: border-box;
            max-width: 500px;
            min-width: 228px;
            white-space: break-spaces!important;
            padding: 4px 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            font-family: $baseFont;
            line-height: 26px;
            border-radius: 4px;
            color: #38434C;
            border: 1px solid #FFFFFF;
          }
          textarea:hover {
            background: #f6f8fd;
          }
          textarea:focus {
            border: 1px solid #0052cc;
          }
        }
      }
    }
  }
}

.default-pointer {
  background: none!important;
  border: 1px solid #fff!important;
  cursor: default!important;
  color: #757575!important;
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  &:hover {
    background: none!important;
    border: 1px solid #fff!important;
  }
}
.disabled-pointer {
  cursor: url('../../../../../../assets/images/input/disabled-cursor.svg'), auto !important;
  .css-v7duua {
    display: none;
  }
  .css-1p3m7a8-multiValue {
    .css-9jq23d {
      color: #757575!important
    }
  }
  &:hover {
    background: none!important;
    border: 1px solid #fff!important;
  }
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.relative-block {
  position: relative!important;
  top: 0!important;
}
.request-modal .modal-body .request-step {
  text-align: left;
}
.modal-opened {
  cursor: default;
}
.profile-wrapper {
  .profile-container {
    margin: 0 auto;
    .std-tooltip {
      background: #38434c!important;
      border-radius: 3px!important;
      box-shadow: none!important;
      color: #fff!important;
      font-size: 12px!important;
      width: max-content;
      margin: -5px 0px 0px 0px;
      padding: 0 6px!important;
      line-height: 24px!important;
    }
    .breadcrumbs-block {
      margin: 16px 0 12px 23px;
    }
  }
  .block-small-max-size {
    width: 796px;
  }
  .css-1p3m7a8-multiValue {
    display: flex;
    min-width: 0;
    background-color: #f2f2f2;
    border-radius: 2px;
    margin: 2px;
    box-sizing: border-box;
  }
  .css-v7duua:hover {
    background-color: #fff0;
  }
  .profile-top-block {
    align-items: center;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    margin: 0 24px 16px 24px;
    padding: 20px 24px 0 24px;
    width: 404px;
    text-align: center;
    box-shadow: 0 0.15px 0.45px rgba(0,0,0,.11), 0 0.8px 1.8px rgba(0,0,0,.13);
    background: #F9FAFD;
    position: sticky;
    top: 90px;
    z-index: 7;
    .bottom-part {
      background: #FFFFFF;
      width: 402px;
      padding: 16px 32px;
      h4 {
        color: #38434C;
        font-family: $baseFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
      }
      .team {
        text-align: left;
        margin-top: 16px;
        span {
          font-family: $baseFont;
          color: #152029;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          vertical-align: top;
          line-height: 24px;
        }
      }
      .contact-me {
        .link {
          text-align: left;
          padding: 2px 0 4px;
          display: flex;
          cursor: pointer;
          width: max-content;
          .copy-block {
            display: flex;
            align-items: center;
          }
          .copy-to-clipboard {
            margin-left: 8px;
            border-radius: 50%;
            width: 28px;
            cursor: pointer;
            height: 28px;
            display: flex;
            align-items: center;
            font-size: 18px;
            padding: 5px;
            margin-top: -3px;
            &:hover {
              background: #E6EEFA;
            }
          }
          .open-in-window {
            border-radius: 50%;
            font-size: 18px;
            height: 28px;
            padding-top: 4px;
            width: 28px;
            display: flex;
            justify-content: center;
            margin-left: -4px;
            &:hover {
              background: #E6EEFA;
            }
          }
          img {
            width: 28px;
          }
          span {
            font-family: $baseFont;
            color: #152029;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-left: 10px;
            vertical-align: top;
            line-height: 28px;
            max-width: 265px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .hierarchy-chain-employee {
        display: flex;
        align-items: center;
        margin-top: 10px;
        &:first-child {
          margin-left: -10px;
        }
      }
      .hierarchy-chain {
        .default-cursor {
          cursor: default;
        }
        .manager-hierarchy {
          display: flex;
          width: 355px;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
    .request-icon {
      margin-left: 87px;
      margin-top: 108px;
      position: absolute;
      z-index: 1;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    .for-hover {
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.35);
        .hover-svg {
          background: rgba(0,0,0,.35);
          border-radius: 50%;
          opacity: 1;
          width: 140px;
          height: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
        }
      }
    }
    .profile-photo {
      align-items: center;
      background: #9aa4be;
      border-radius: 50%;
      display: flex;
      height: 140px;
      width: 140px;
      position: relative;
      .react-tooltip {
        background: #fff!important;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 2px rgba(0, 0, 0, 0.04), 0 16px 24px -3px rgba(0, 0, 0, 0.06);
        color: #38434c!important;
        font-family: Roboto,sans-serif;
        margin-top: -5px;
        max-width: 300px;
        width: max-content;
        text-align: left;
        margin-left: 0!important;
        opacity: 1!important;
        padding: 2px 8px 8px 8px!important;
        z-index: 999;
        position: absolute;
        height: max-content;
        span {
          padding: 0;
          height: max-content;
        }
        .additional-paid-time-off {
          padding: 5px;
          margin-top: -7px;
        }
        .popup-main-text {
          font-size: 16px;
          font-weight: 500;
          color: #003563;
          margin-top: 6px;
        }
        .popup-main-holiday-text {
          color: #003563;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          margin-left: 5px;
        }
        .company, .holiday-text {
          background: #e6eefa;
          border-radius: 4px;
          height: 20px;
          margin-bottom: 7px;
          padding: 0 6px;
          width: max-content;
        }
        .employee-block {
          display: flex;
          flex-direction: row;
          align-content: center;
          height: 36px;
        }
        .type-block {
          margin-top: 7px;
          align-items: center;
          align-content: center;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          height: 24px;
          padding: 0 5px;
          margin-bottom: 7px;
          width: max-content;
          box-sizing: border-box; /* new line */

          .vacation-block {
            width: 20px;
            height: 20px;
            box-sizing: border-box; /* new line */
          }

          span {
            margin-top: 3px;
            margin-left: 5px;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
        .not-confirmed {
          background: #f2f2f2;
          color: #38434c;
          font-size: 14px;
          height: 20px;
          padding: 1px 5px;
          border-radius: 4px;
        }
        .replacing {
          color: #38434c;
          font-size: 14px;
          height: 20px;
          padding: 1px 5px;
          border-radius: 4px;
        }
        .working-hours {
          font-weight: 500;
          font-size: 16px;
        }
        .schedule-block {
          display: flex;
          white-space: nowrap;
          font-size: 14px;
          margin-top: 5px;
          span {
            font-size: 14px;
            font-weight: 500;
            margin-left: 5px;
          };
          i {
            font-size: 22px;
            margin-right: 4px;
            margin-top: -2px;
          }
        }
        .tool-date {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 7px 0;
          i {
            display: flex;
            justify-content: end;
            width: 20px;
            font-size: 18px;
            color: #6A7885;
            span {
              font-weight: 600;
            }
          }
        }
        .without-avatar {
          background-color: #9aa4be;
          border: 1px solid #e6ecf1;
          border-radius: 25px;
          display: inline-grid;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          p {
            color: #fff;
            font-family: Roboto,sans-serif;
            line-height: 31px;
            text-align: center;
            width: 30px;
            height: 30px;
          }
        }
        .with-avatar {
          margin-right: 8px;
          display: inline-grid;
          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
          }
        }
      }
      p {
        font-size: 85px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        font-family: $baseFont;
        cursor: default;
      }
      .hover-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0; // По умолчанию скрыто
        transition: opacity 0.3s; // Плавное появление
      }
    }
    .profile-info {
      h2 {
        color: #152029;
        font-family: $baseFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        margin-top: 12px;
      }
      .position {
        font-family: $baseFont;
        line-height: 24px;
        color: #38434C;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        padding: 0 24px;
      }
      .employee-start {
        font-family: $baseFont;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #38434C;
      }
      .border-line {
        border-bottom: 0.5px solid #dfe1e6;
        padding-bottom: 10px;
      }
      .hierarchy-chain {
        color: $blackBaseColor;
        font-family: $baseFont;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        padding: 12px 0 0;
        h4 {
          margin-bottom: -6px;
        }
        a, a:visited {
          text-decoration: none;
          color: #38434c;
          &:hover {
            text-decoration: revert;
          }
        }
        .manager-nickname {
          background-color: $greyLight;
          border-radius: 3px;
          padding: 5px 4px;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .material-icons {
          font-size: 14px;
          color: #38434C;
          margin-bottom: -12px;
        }
      }
      .profile-btns {
        display: flex;
        justify-content: center;
        margin: 12px 0 20px 0;
        .request-salary, .additional-pays {
          svg {
            margin: -1px 1px -5px -5px;
          }
          span {
            color: #049ED4;
          }
          &:hover {
            background: #D5E9FC;
          }
          color: #003563;
          font-weight: 500;
          font-size: 14px;
          font-family: $baseFont;
          display: flex;
          width: 162px;
          padding: 4px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 30px;
          border: 1px solid #049ED4;
          background: #FFF;
          cursor: pointer;
        }
        .request-salary {
          margin-right: 12px;
        }
      }
    }
  }
  .profile-sub-top-block {
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 -1px 0 hsla(208,9%,65%,.2);
    display: flex;
    flex-direction: column;
    padding: 12px 32px 16px;
    align-items: flex-start;
    h4 {
      color: #38434c;
      font-family: Roboto,sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 4px;
      text-align: left;
    }
    .clickable {
      cursor: pointer;
    }
    .time-off-block {
      display: flex;
      flex-wrap: wrap;
    }
    .time-off-type-block {
      align-items: center;
      border-radius: 5px;
      display: flex;
      gap: 16px;
      padding: 3px 8px 4px 18px;
      margin-right: 9px;
      margin-bottom: 12px;
      p {
        font-family: $baseFont;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #152029;
      }
      div {
        margin-left: -10px;
        align-items: center;
        display: flex;
        margin-top: 1px;
      }
    }
    .request {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 12px;
      width: 125px;
      height: 36px;
      background: #E6EEFA;
      border-radius: 4px;
      color: #0044AA;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      font-family: $baseFont;
      cursor: pointer;
    }
  }
  .profile-block {
    flex-direction: column;
    margin: 0 auto;
    .time-off-type-block {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      padding: 6px 16px;
      gap: 16px;
      min-width: 177px;
      width: auto;
      height: 36px;
      background: #FFFFFF;
      border: 0.5px solid #C2CAD1;
      border-radius: 5px;
      p {
        font-family: $baseFont;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #152029;
      }
      div {
        margin-left: -10px;
        align-items: center;
        display: flex;
        margin-top: 1px;
      }
    }
    .request {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 12px;
      width: 125px;
      height: 36px;
      background: #E6EEFA;
      border-radius: 4px;
      color: #0044AA;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      font-family: $baseFont;
      cursor: pointer;
    }

    .input .error {
      margin-left: 8px;
      min-width: 210px;
      margin-top: 15px;
      width: max-content;
    }
    .sub-content-main .content {
      padding-top: 0;
      width: 100%;
    }
    .sub-content-header {
      flex-direction: row;
      height: 50px;
      padding-right: 0;
      padding-top: 0;
      position: relative;
      width: 100%;
      z-index: 2;
      border-bottom: 1px solid #DDE4EB;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .sub-content-main {
      padding: 0;
    }
    .employee-profile-menu {
      display: flex;
      list-style-type: none;
      position: relative;
      width: auto;
      z-index: 1;
      li {
        color: #38434c;
        cursor: pointer;
        font-family: $baseFont;
        font-size: 16px;
        font-weight: 500;
        margin: 0 10px;
        padding: 8px 16px;
        border-radius: 4px;
        &:hover {
          background: #E6EEFA;
          border-radius: 40px;
        }
      }
      .active {
        background: #0044AA;
        color: #FFFFFF;
        border-radius: 40px;
        &:hover {
          background: #0044AA;
          color: #FFFFFF;
          border-radius: 40px;
        }
      }
    }
  }
}
.modal-header {
  background-color: #F9FAFD;
  color: #152029;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px 42px;
  box-shadow: inset 0px -0.5px 0px #C2CAD1;
}

.types-link {
  margin: 10px 0;
  a {
    color: #0052cc;
  }
}
.balance-field {
  .css-b62m3t-container {
    width: 100%!important;
  }
}
.react-select__control {
  max-width: 100%!important;
}
.icon-end {
  width: 24px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  pointer-events: none;
  font-size: 21px;
  color: #AFB7BE;
  margin-top: 16px;
  right: 52%;
}
.icon-start {
  width: 24px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  pointer-events: none;
  font-size: 21px;
  color: #AFB7BE;
  left: 0;
  margin-top: 16px;
  z-index: 1;
}
.modal-header .material-icons {
  color: #6A7885;
  cursor: pointer;
}
.modal-footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 12px 24px;
  background: #FCFDFE;
  box-shadow: inset 0 0.5px 0 0 #C2CAD1;

}
.request-modal {
  .modal-content {
    min-width: 497px!important;
    width: auto!important;
    max-width: min-content!important;
  }
  .modal-body {
    padding: 0;
    .available-block {
      display: flex;
      justify-content: space-between;
      span:last-child {
        color: #6A7885;
      }
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #38434C;
      font-family: $baseFont;
      padding-top: 10px;
      span {
        font-weight: 400;
      }
      &:first-child {
        padding-top: 0;
      }
    }
    .date-block {
      display: flex;
      flex-direction: row;
      .react-datepicker__navigation {
        top: 20px !important;
      }
      .react-datepicker__day--disabled {
        color: #ccc !important;
      }
    }
    .horizontal-input label {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      min-width: auto;
      width: max-content;
      margin-right: 8px;
    }
    .css-b62m3t-container, .css-b62m3t-container {
      position: relative;
      box-sizing: border-box;
      min-width: 204px;
      width: max-content;
      margin-bottom: 10px;
    }
    .horizontal-input .input-container, .horizontal-input label {
      padding: 0;
    }
  }
}
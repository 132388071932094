@import "./src/utils/variables/base";

.calendar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 0 32px;
    position: relative;
    p {
        color: var(--black-black-dark, #152029);
        font-family: $baseFont;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .material-symbols-container {
        display: flex;
        gap: 8px;
    }
    .symbol-wrapper {
        padding: 4px 8px;
        background-color: #F9FAFD;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease;
    }

    .symbol-wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: transparent;
        z-index: -1;
        transition: background-color 0.3s ease;
    }

    .symbol-wrapper .material-symbols-outlined {
        color: #6A7885;
        font-size: 24px;
        flex-shrink: 0;
    }

    .symbol-wrapper:hover .material-symbols-outlined {
        color: #0052CC;
    }

    .symbol-wrapper:hover {
        background-color: #E6EEFA;
    }

    .symbol-wrapper:hover::before {
        background-color: #E6EEFA;
    }
}
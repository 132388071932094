@import "./src/utils/variables/base";
@import "./src/utils/variables/input";

.modal-closed {
  display: none;
}
.modal-opened {
  display: block;
}

.modal {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 652px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.modal-header {
  padding: 15px 20px 15px 60px;
  background-color: #0073D6;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    font-family: $baseFont;
    font-weight: 400;
  }
  .material-icons {
    color: #FFFFFF;
    cursor: pointer;
  }
}

.modal-body {
  padding: 24px 60px;
}

.modal-footer {
  padding: 12px 24px;
  background-color: #F6F8FD;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  a {
    color: $brandForegroundColor;
    text-decoration: none;
    font-family: $baseFont;
    padding: 6px 12px;
  }
}
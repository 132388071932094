@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.sub-menu {
  background: #fff;
  box-shadow: 0 0.15px 0.45px rgb(0 0 0 / 11%), 0 0.8px 1.8px rgb(0 0 0 / 13%);
  height: auto;
  max-height: calc(100% - 180px);
  margin: 90px 24px 24px;
  padding: 32px 18px 32px 26px;
  position: fixed;
  width: 244px;
  overflow-y: auto;
  ul {
    li {
      list-style-type: none;
      padding: 10px 0;
      span, a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $blackBaseColor;
        text-decoration: none;
        font-family: $baseFont;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      div {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
      ul {
        border-left: 2px solid #E6EEFA;
        margin-top: 10px;
        li {
          padding-left: 12px;
        }
        li a {
          font-weight: 400;
        }
        li:first-child {
          padding-top: 10px;
        }
        li:last-child {
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }

    li:first-child {
      padding-top: 0;
    }
    li:last-child {
      padding-bottom: 0;
      padding-top: 0;
    }
    .sub-menu-active {
      padding-bottom: 0;
    }
    .inner-sub-menu-active {
      background-color: #F6F8FD;
      box-shadow: inset 2px 0 0 $brandForegroundColor;
    }
  }
}
.hide {
  display: none;
}
@import "src/utils/variables/base";
@import "src/utils/variables/shadow";

.not-found {
  align-items: center;
  color: #6a7885;
  font-family: Roboto,sans-serif;
  margin-top: 76px;
  text-align: center;
  height: calc(100vh - 191px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  a, a:visited {
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    background: var(--blue-light, #E6EEFA);
    margin-top: 26px;
    text-decoration: none;
    color: var(--blue-main, #0052CC);
    text-align: center;
    font-family: $baseFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }
  h1 {
    color: var(--black-dark-blue, #003563);
    font-family: $baseFont;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  p {
    span {
      color: var(--blue-main, #0052CC);
      font-family: $baseFont;
      font-size: 128px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
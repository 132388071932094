@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

header {
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  background-color: #FFF;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  z-index: 8;
  border-bottom: 1px solid #DFE1E6;

  .header-container {
    max-width: 1564px;
    width: 95%;
    margin: 0 auto;
    padding: 8px 24px 8px 20px;
  }
  .header-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .logo {
    width: 100px;
  }

  .logo-block {
    width: 58px;
    background-color: #fff;
  }

  .user-block {
    background-color: #fff;
  }

  .personal-info-block {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .without-avatar {
    background-color: #9AA4BE;
    height: 37px;
    width: 37px;
    border-radius: 25px;
    border: 1px solid #E6ECF1;
    margin-right: 13px;

    p {
      color: #FFF;
      font-family: $baseFont;
      line-height: 37px;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .avatar {
    background-color: #9AA4BE;
    height: 37px;
    width: 37px;
    border-radius: 25px;
    border: 1px solid #E6ECF1;
    margin-right: 13px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .profile-name {
    p {
      font-size: 14px;
      font-weight: 500;
      font-family: $baseFont;
      line-height: 30px;
      margin-right: 10px;
    }

    .arrow {
      border: solid #6A7885;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      margin-top: -4px;
    }

    .down {
      transform: rotate(45deg);
    }
  }

  .logout-icon-box {
    float: left;
    padding: 6px 10px 2px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  .logout-icon-box:hover {
    background-color: $greyLight;
  }

  .search-input-container {
    height: 36px;
    overflow: visible;
    margin-right: auto;
    display: flex;
  }

  .checkin-button-group {
    display: flex;
    gap: 4px;
    margin-right: 24px;

    button {
      border: 1px solid var(--white, #FFF);
      box-shadow: 0 0.8px 1.8px 0 rgba(0, 0, 0, 0.13), 0 0.15px 0.45px 0 rgba(0, 0, 0, 0.11);
    }
  }
}
@import "./src/utils/fonts/roboto";
@import "./src/utils/variables/input";
@import "./src/utils/variables/base";

.input {
    .input-container {
        textarea {
            resize: none;
        }
        input[type="text"], input[type="password"], textarea {
            font-family: $baseFont;
            width: 100%;
            height: 44px;
            background-color: $defaultBackgroundColor;
            border: .5px solid $borderColor;
            border-radius: 4px;
            color: $blackBaseColor;
            font-weight: 400;
            font-size: 16px;
            padding: 8px 12px;
            transition: border .1s ease-in-out, box-shadow .1s ease-in-out;
            &::placeholder {
                color: $defaultForegroundDisabledColor;
            }
            &:focus {
                border: .5px solid $brandForegroundColor;
                box-shadow: 0 1.2px 3.6px rgba(0, 0, 0, 0.03);
            }
        }
    }
}

.horizontal-input {
    .input-container {
        input[type="text"], input[type="password"], textarea {
            height: 36px !important;
        }
    }
}
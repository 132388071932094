@import "./src/utils/variables/base";
@import "./src/utils/variables/input";

.modal-closed {
  display: none;
}
.modal-opened {
  display: block;
}

.confirm-modal {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  .modal-body {
    padding: 24px 28px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    p {
      line-height: 24px;
      font-family: $baseFont;
      color: $blackBaseColor;
      word-wrap: break-word;
      span {
        font-weight: 500;
      }
    }
    a {
      text-decoration: none;
    }
  }

  .modal-footer {
    padding: 12px 24px;
    background-color: #F9FAFD;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    a {
      color: $blackBaseColor;
      text-decoration: none;
      font-family: $baseFont;
      padding: 6px 12px;
    }
    .ghost {
      color:$blackBaseColor;
    }
  }
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    max-width: 428px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  .modal-header {
    padding: 15px 28px;
    color: $blackBaseColor;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-family: $baseFont;
      font-weight: 400;
    }
    .material-icons {
      color: #6A7885;
      cursor: pointer;
    }
  }
}


@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
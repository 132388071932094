@import "./src/utils/variables/base";

.horizontal-input {
  .checkbox-container {
    display: flex;
    align-items: center;
    padding: 0 15px;

    input[type="checkbox"] {
      font-size: 16px;
      font-weight: 400;
      font-family: $baseFont;
      float: left;
      height: 18px;
      margin-right: 10px;
      width: 18px;
    }
    span {
      color: $blackBaseColor;
    }

    .switch {
      display: inline-block;
      position: relative;
      width: 38px!important;
      min-width: 38px;
      height: 28px;
      input {
        display:none;
      }
      .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 14px;
        transition: .4s;
      }

      .clicked:before {
        background-color: #4088F8;
        bottom: -3px;
        content: "";
        height: 20px;
        left: -3px;
        position: absolute;
        transition: .4s;
        width: 20px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      }
      .unclicked:before {
        background-color: #FFFFFF;
        bottom: -3px;
        content: "";
        height: 20px;
        left: -3px;
        position: absolute;
        transition: .4s;
        width: 20px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      }

      .label-text {
        color: #38434c;
        font-style: normal;
        line-height: 41px;
        white-space: nowrap;
        margin-left: 38px;
      }

      input:checked + .slider {
        background-color: #aacbff;
        width: 38px;
        height: 14px;
      }

      input:checked + .slider:before {
        transform: translateX(26px);
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
  .checkbox-container-simple {
    display: flex;
    align-items: center;
    padding: 0 15px;

    input[type="checkbox"] {
      font-size: 16px;
      font-weight: 400;
      font-family: $baseFont;
      float: left;
      height: 18px;
      margin-right: 10px;
      width: 18px;
    }
    span {
      color: $blackBaseColor;
    }

    .switch {
      display: inline-block;
      position: relative;
      width: 38px!important;
      min-width: 38px;
      height: 28px;
      input {
        display:none;
      }
      .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 14px;
        transition: .4s;
      }

      .clicked:before {
        background-color: #4088F8;
        bottom: -3px;
        content: "";
        height: 20px;
        left: -3px;
        position: absolute;
        transition: .4s;
        width: 20px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      }
      .unclicked:before {
        background-color: #FFFFFF;
        bottom: -3px;
        content: "";
        height: 20px;
        left: -3px;
        position: absolute;
        transition: .4s;
        width: 20px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      }

      .label-text {
        color: #38434c;
        font-style: normal;
        line-height: 41px;
        white-space: nowrap;
        margin-left: 38px;
      }

      input:checked + .slider {
        background-color: #aacbff;
        width: 38px;
        height: 14px;
      }

      input:checked + .slider:before {
        transform: translateX(26px);
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}

//Styles for the simple checkbox - without label
.only-input-container .horizontal-input {
  .checkbox-container {
    padding: 0;
  }

  .input {
    display: flex;
  }

  label {
    display: none;
  }
}
@import "./src/utils/variables/base";
@import "./src/utils/variables/shadow";

.teams-z-index {
  z-index: 8;
}
.sub-content-block {
  .z-index-fix {
    z-index: 0;
  }
  .teams {
    .add-button-block {
      margin-top: -70px;
      position: fixed;
      right: 50px;
      z-index: 2;
    }
    .vertical-tabs {
      display: flex;
      .vertical-tabs-menu {
        padding: 16px 12px;
        width: 207px;
        box-shadow: 0 0.15px 0.45px rgb(0 0 0 / 11%), 0 0.8px 1.8px rgb(0 0 0 / 13%);
        background: #fff;
        overflow-y: auto;
        height: calc(100% - 244px);
        position: fixed;
        .active-company-tab {
          background: #F2F2F2;
          border-radius: 4px;
          color: #152029;
        }
        h3 {
          font-family: $baseFont;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #6A7885;
          letter-spacing: 0.02em;
          padding: 4px 12px;
        }
        p {
          color: #38434C;
          font-family: $baseFont;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          padding: 6px 12px;
          margin: 4px 0;
          cursor: pointer;
          line-height: 140%;
          word-break: normal;
        }
      }
      .vertical-tabs-content {
        padding: 16px 20px;
        box-shadow: 0 0.15px 0.45px rgb(0 0 0 / 11%), 0 0.8px 1.8px rgb(0 0 0 / 13%);
        background: #fff;
        overflow-y: auto;
        height: calc(100% - 244px);
        position: fixed;
        margin-left: 207px;
        width: calc(100% - 625px);
        border-left: 0.5px solid #c2cad1;
        ul {
          margin: 0 15px;
          li {
            color: #152029;
            list-style-type: none;
            font-family: $baseFont;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            .empty {
              width: 22px;
            }
            .btns-block {
              .material-icons {
                font-size: 18px;
                margin-top: 0!important;
                margin-left: 4px;
              }
            }
          }
          .without-child {
            margin-left: 18px;
          }
          .team-block {
            display: flex;
            border-radius: 4px;
            padding: 5px 10px 3px 10px;
            width: max-content;
            .show-click {
              cursor: pointer;
            }
            span {
              margin-right: 10px;
            }
            .material-icons {
              margin-top: -2px;
              margin-left: -7px;
              cursor: pointer;
            }
          }
          .team-block:hover {
            background: #f2f2f2;
          }
        }
      }
    }
  }
}